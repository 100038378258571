import axios from "axios";
import { action, actionOn, persist, thunk } from "easy-peasy";

import { OnboardingMethodsEnum } from "../common/enums/enum";
import {
  BreggCompanyGetDTO,
  BreggSearchCompanyDTO,
  CompanyOnboardingModel,
  OnboardingShareholderGetDTO,
  OnboardingStakeholderGetDTO,
  OnboardingTransactionGetDTO,
  RegularIntegration,
  UniMicroCompanyGetDTO,
} from "./modelTypes";
import { getThunkTypes } from "./storeThunk";

export const companyOnboardingModel: CompanyOnboardingModel = {
  company: null,
  companyShareClasses: [],
  isGetCompanyLoading: false,
  isPostCompanyLoading: false,
  transaction: null,
  shareholders: [],
  lastSavedAt: "",
  integrations: persist({
    bregg: null,
    uniMicro: null,
    regular: null,
  }),
  founders: [],
  //actions
  setCompany: action((state, company) => {
    state.company = company;
  }),
  setGetCompanyLoading: action((state, payload) => {
    state.isGetCompanyLoading = payload;
  }),
  setPostCompanyLoading: action((state, payload) => {
    state.isPostCompanyLoading = payload;
  }),
  setShareholders: action((state, payload) => {
    state.shareholders = payload;
  }),
  setIntegration: action((state, payload) => {
    switch (payload.type) {
      case "bregg":
        state.integrations.bregg = payload.data as BreggCompanyGetDTO;
        break;
      case "uniMicro":
        state.integrations.uniMicro = payload.data as UniMicroCompanyGetDTO;
        break;
      case "regular":
        state.integrations.regular = payload.data as RegularIntegration;
        break;
      case "all":
        state.integrations.bregg = null;
        state.integrations.uniMicro = null;
        state.integrations.regular = null;
        break;
    }
  }),
  setCompanyShareClasses: action((state, payload) => {
    state.companyShareClasses = payload;
  }),
  setTransaction: action((state, payload) => {
    state.transaction = payload;
  }),
  setLastSavedAt: action((state, payload) => {
    state.lastSavedAt = payload;
  }),
  // thunks
  getCompanyThunk: thunk((_, companyId, { fail }) => {
    return axios.get(`/api/onboarding/company/${companyId}`).catch(fail);
  }),
  setCompanyThunk: thunk((_, company, { fail }) => {
    return axios.post("/api/onboarding/company", company).catch(fail);
  }),
  getShareClassesThunk: thunk((_, companyId, { fail }) => {
    return axios.get(`/api/onboarding/company/share-classes/${companyId}`).catch(fail);
  }),
  searchBrregCompanyThunk: thunk(async (_, payload) => {
    return await axios.get<BreggSearchCompanyDTO>(`/api/onboarding/company/search/${encodeURI(payload)}`);
  }),
  getBreggCompanyInfoThunk: thunk(async (actions, payload) => {
    actions.setGetCompanyLoading(true);
    try {
      const req = await axios.get<BreggCompanyGetDTO>(`/api/onboarding/company/brreg/${payload}`);

      actions.setIntegration({ type: "bregg", data: req.data });

      return req;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      actions.setGetCompanyLoading(false);
    }
  }),
  getUniMicroCompanyInfoThunk: thunk(async (actions, { iss, accessCode }) => {
    actions.setGetCompanyLoading(true);
    try {
      const req = await axios.post<UniMicroCompanyGetDTO>("/api/onboarding/company/unimicro", { accessCode, iss });

      actions.setIntegration({ type: "uniMicro", data: req.data });

      return req;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      actions.setGetCompanyLoading(false);
    }
  }),
  getShareholdersThunk: thunk(async (actions, companyId) => {
    actions.setGetCompanyLoading(true);
    try {
      const req = await axios.get<OnboardingShareholderGetDTO>(`/api/onboarding/company/shareholders/${companyId}`);
      if (req.data) {
        actions.setShareholders(req.data?.shareholders || []);
        actions.setCompanyShareClasses(req.data?.shareClasses || []);
        actions.setLastSavedAt(req.data?.lastSavedAt || "");
      }
      return req;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      actions.setGetCompanyLoading(false);
    }
  }),
  getStakeholdersThunk: thunk(async (actions, companyId) => {
    actions.setGetCompanyLoading(true);
    try {
      const req = await axios.get<OnboardingStakeholderGetDTO>(`/api/onboarding/company/stakeholders/${companyId}`);
      if (req.data) {
        actions.setShareholders(req.data?.stakeholders || []);
        actions.setLastSavedAt(req.data?.lastSavedAt || "");
      }
      return req;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      actions.setGetCompanyLoading(false);
    }
  }),
  setStakeholdersThunk: thunk(async (actions, payload) => {
    actions.setGetCompanyLoading(true);
    try {
      return await axios.post("/api/onboarding/company/stakeholders", payload);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      actions.setGetCompanyLoading(false);
    }
  }),
  setShareholdersThunk: thunk(async (actions, payload) => {
    actions.setPostCompanyLoading(true);
    try {
      return await axios.post("/api/onboarding/company/shareholders", payload);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      actions.setPostCompanyLoading(false);
    }
  }),
  getTransactionsThunk: thunk(async (actions, companyId) => {
    actions.setGetCompanyLoading(true);
    try {
      const req = await axios.get<OnboardingTransactionGetDTO>(`/api/onboarding/company/transactions/${companyId}`);
      if (req.data) {
        actions.setTransaction(req.data);
      }
      return req;
    } catch (err) {
      return Promise.reject(err);
    } finally {
      actions.setGetCompanyLoading(false);
    }
  }),
  setTransactionsThunk: thunk(async (actions, payload) => {
    actions.setPostCompanyLoading(true);
    try {
      return await axios.post("/api/onboarding/company/transactions", payload);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      actions.setPostCompanyLoading(false);
    }
  }),
  setFoundersThunk: thunk(async (actions, payload) => {
    actions.setPostCompanyLoading(true);
    try {
      return await axios.post("/api/onboarding/company/foundation", payload);
    } catch (err) {
      return Promise.reject(err);
    } finally {
      actions.setPostCompanyLoading(false);
    }
  }),
  // actionsOn
  getCompanyAction: actionOn(
    (actions) => getThunkTypes(actions.getCompanyThunk),
    (state, target) => {
      const [startType, successType] = target.resolvedTargets;
      state.isGetCompanyLoading = startType === target.type;

      switch (target.type) {
        case startType:
          state.company = null;
          break;
        case successType:
          state.company = target.result.data;
          if (target.result.data?.onboardingMethod !== OnboardingMethodsEnum.NO_INTEGRATION) {
            if (target.result.data?.onboardingMethod === OnboardingMethodsEnum.BR_REG) {
              state.integrations.bregg = {
                shareholders: target.result.data?.shareholders,
                shareClasses: target.result.data?.shareClasses,
              };
            } else if (
              target.result.data?.onboardingMethod === OnboardingMethodsEnum.UNI_MICRO ||
              target.result.data?.onboardingMethod === OnboardingMethodsEnum.SR_BANK_UNI_MICRO
            ) {
              state.integrations.uniMicro = {
                shareholders: target.result.data?.shareholders,
                shareClasses: target.result.data?.shareClasses,
              };
            }
          }
      }
    }
  ),
  setCompanyAction: actionOn(
    (actions) => getThunkTypes(actions.setCompanyThunk),
    (state, target) => {
      const [startType, successType] = target.resolvedTargets;
      state.isPostCompanyLoading = startType === target.type;

      switch (target.type) {
        case successType:
          state.company = target.result.data;
      }
    }
  ),
  getShareClassesAction: actionOn(
    (actions) => getThunkTypes(actions.getShareClassesThunk),
    (state, target) => {
      const [startType, successType] = target.resolvedTargets;
      state.isGetCompanyLoading = startType === target.type;

      switch (target.type) {
        case startType:
          state.companyShareClasses = [];
          break;
        case successType:
          state.companyShareClasses = target.result.data;
      }
    }
  ),

  companyType: null,
  setCompanyType: action((state, payload) => {
    state.companyType = payload;
  }),
};
