import { FC, ReactNode, useMemo } from "react";
import { Spinner } from "react-bootstrap";
import BButton, { ButtonProps as BButtonProps } from "react-bootstrap/Button";
import cn from "classnames";
import { defaultTo } from "ramda";

import LinkHelpText from "../LinkHelpText/LinkHelpText";
import classes from "./Button.module.scss";

export type ButtonVariants = "primary" | "secondary" | "tertiary" | "danger" | "dark" | "success" | "information";

export type ButtonSizes = "s" | "m";

export type ButtonCustomProps = {
  variant?: ButtonVariants;
  size?: ButtonSizes;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  isLoading?: boolean;
  isDisabled?: boolean;
  isOnlyIcon?: boolean;
  isFocusDisabled?: boolean;
  tooltipMessage?: string;
  tooltipTitle?: string;
  dataTestId?: string;
};

export type ButtonProps = Omit<BButtonProps, "variant" | "size" | "disabled"> & ButtonCustomProps;

const ButtonComponent: FC<ButtonProps> = ({
  variant = "primary",
  size = "m",
  isLoading = false,
  isDisabled = false,
  isOnlyIcon = false,
  isFocusDisabled = false,
  iconLeft,
  iconRight,
  className,
  children,
  tooltipMessage,
  dataTestId,
  ...props
}) => {
  const buttonVariant = useMemo<BButtonProps["variant"]>(() => {
    switch (variant) {
      case "secondary":
        return "secondary";
      case "tertiary":
        return "light";
      case "danger":
        return "danger";
      case "success":
        return "success";
      case "dark":
        return "outline-dark";
      case "information":
        return "information";
      case "primary":
      default:
        return "primary";
    }
  }, [variant]);

  return (
    <BButton
      disabled={isLoading || isDisabled}
      variant={buttonVariant}
      size={size === "s" ? "sm" : undefined}
      className={cn(
        classes.button,
        {
          [classes["round"]]: isOnlyIcon,
          [classes["no-focus"]]: isFocusDisabled,
          [classes["icon-left"]]: !!iconLeft,
          [classes["icon-right"]]: !!iconRight,
        },
        className
      )}
      data-testid={dataTestId}
      {...props}
    >
      {!!iconLeft && (
        <span className={cn({ "me-1": !isOnlyIcon, invisible: isLoading }, classes.icon)}>{iconLeft}</span>
      )}
      <span className={cn({ invisible: isLoading })}>{children}</span>
      {!!iconRight && (
        <span className={cn({ "ms-1": !isOnlyIcon, invisible: isLoading }, classes.icon)}>{iconRight}</span>
      )}

      {isLoading && (
        <Spinner
          size={size === "s" ? "sm" : undefined}
          style={{ fontSize: "16px" }}
          animation="border"
          role="status"
          as="span"
          aria-hidden="true"
          className="position-absolute"
        />
      )}
    </BButton>
  );
};

const Button: FC<ButtonProps> = ({ tooltipTitle, tooltipMessage, className, style, ...props }) => {
  if (tooltipMessage && props.isDisabled) {
    return (
      <LinkHelpText
        tooltipWithTitle={Boolean(tooltipTitle)}
        title={defaultTo("", tooltipTitle)}
        content={tooltipMessage}
        className={className}
        overlayClass={classes.tooltip}
        style={style}
      >
        <ButtonComponent {...props}>{props.children}</ButtonComponent>
      </LinkHelpText>
    );
  }

  return (
    <ButtonComponent className={className} style={style} {...props}>
      {props.children}
    </ButtonComponent>
  );
};

export default Button;
