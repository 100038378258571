import { forwardRef, useCallback, useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import cn from "classnames";
import * as R from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import CollapsibleContainer from "common/components/atoms/Collapsible/Collapsible";
import Table from "common/components/atoms/Table/Table";
import { Ui } from "common/components/atoms/Typography";
import { InstrumentTypesIdsEnum, InstrumentTypesNamesEnum } from "common/enums/enum";
import { InformationCircleIcon } from "common/icons/svg";
import { CollapsibleHeaderProps, OwnershipProgramTableData, sortingParams } from "common/types/Collapsible.types";
import { sortProgramTableData } from "common/utils/functions";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import { PlanStatuses } from "../../../../ownership/documents/documents.types";
import OneOffPlansHeader from "./OneOffPlansHeader";
import classes from "./ProgramContainer.module.scss";
import ProgramHeader from "./ProgramHeader";
import ProgramTableContent from "./ProgramTableContent";
import ProgramTableHead from "./ProgramTableHead";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans");

type commonProgramContainerProps = {
  defaultOpen?: boolean;
  cliff?: number;
  programID?: number;
  data: OwnershipProgramTableData[];
  onHeadPress?: (index?: number) => void;
  onGrantAllPlansPress?: (programId: number) => void;
  index?: number;
};

type ProgramContainerProps = commonProgramContainerProps &
  (
    | {
        title: string;
        status: string;
        description: string;
        vestingPeriod: number;
        vestingPeriodTimeUnit: string;
        stockType: InstrumentTypesNamesEnum;
        stockTypeId: InstrumentTypesIdsEnum;
        isOneOffIndividualPlan?: boolean;
        cliffTimeUnit?: string;
      }
    | {
        title?: string;
        status?: string;
        description?: string;
        vestingPeriod?: number;
        vestingPeriodTimeUnit?: string;
        stockTypeId?: InstrumentTypesIdsEnum;
        stockType?: InstrumentTypesNamesEnum;
        isOneOffIndividualPlan: true;
        cliffTimeUnit?: string;
      }
  );

export type CurrentSortValue = {
  field: keyof OwnershipProgramTableData;
  type: keyof typeof sortingParams;
};

const ProgramContainer = forwardRef<HTMLDivElement, ProgramContainerProps>(
  (
    {
      data = [],
      title,
      status,
      programID,
      stockTypeId,
      stockType,
      defaultOpen,
      description,
      vestingPeriod,
      vestingPeriodTimeUnit,
      cliffTimeUnit,
      cliff,
      onHeadPress,
      isOneOffIndividualPlan = false,
      index,
    },
    ref
  ) => {
    const navigate = useNavigate();

    const isStockOptions = stockTypeId === InstrumentTypesIdsEnum.OPTION;

    const [sortedValueActive, setSortedValueActive] = useState<CurrentSortValue>({
      field: "none",
      type: sortingParams.inc,
    });

    const rows = useMemo(
      () => [
        {
          key: "representedBy",
          value: t("table.planOwner"),
        },
        ...(isOneOffIndividualPlan ? [{ key: "type", value: t("table.type") }] : []),
        ...(isStockOptions || isOneOffIndividualPlan
          ? [{ key: "exercisePrice", value: t("table.exercisePrice") }]
          : []),
        {
          key: "vestedShares",
          value: t("table.progress"),
        },
        ...(isStockOptions || isOneOffIndividualPlan ? [{ key: "exercisedShares", value: t("table.exercised") }] : []),
        {
          key: "startDate",
          value: t("table.startDate"),
        },
        {
          key: "endTime",
          value: t("table.endDate"),
        },
        {
          key: "optionsExpirationDate",
          value: t("table.expiryDate"),
        },
        {
          key: "statusName",
          value: t("table.status"),
        },
        {
          key: "invitationSent",
          value: t("table.invited"),
        },
        {
          key: "terms",
          value: t("table.terms"),
        },
      ],
      [isOneOffIndividualPlan, isStockOptions]
    );

    const sortedActivePlansTableData = useMemo(
      () =>
        sortProgramTableData({
          data: data,
          sortedField: sortedValueActive.field,
          sortType: sortedValueActive.type,
        }),
      [data, sortedValueActive]
    );

    const handleHeadPress = useCallback(() => {
      onHeadPress?.(index);
    }, [index, onHeadPress]);

    const handleAddPlan = useCallback(() => {
      if (isOneOffIndividualPlan) {
        navigate(getEMPath(["createOneOffPlan", "planReceiver"]));
        return;
      }

      navigate(getEMPath(["createPlan", "basics"], { programId: programID }));
    }, [navigate, isOneOffIndividualPlan, programID]);

    const OneOffPlansHeaderInfo = useCallback(
      ({ activeEventKey, onClick }: CollapsibleHeaderProps) => (
        <OneOffPlansHeader
          ref={ref}
          activeEventKey={activeEventKey}
          onClick={onClick}
          data={data}
          handleAddPlan={handleAddPlan}
          onHeadPress={handleHeadPress}
        />
      ),
      [data, handleAddPlan, handleHeadPress, ref]
    );

    const ProgramHeaderInfo = useCallback(
      ({ activeEventKey, onClick }: CollapsibleHeaderProps) => {
        return (
          <ProgramHeader
            ref={ref}
            title={title}
            status={status}
            stockType={stockType}
            description={description}
            vestingPeriod={vestingPeriod}
            vestingPeriodTimeUnit={vestingPeriodTimeUnit}
            cliffTimeUnit={cliffTimeUnit}
            cliff={cliff}
            onHeadPress={handleHeadPress}
            activeEventKey={activeEventKey}
            onClick={onClick}
            data={data}
            handleAddPlan={handleAddPlan}
          />
        );
      },
      [
        ref,
        title,
        status,
        stockType,
        description,
        vestingPeriod,
        vestingPeriodTimeUnit,
        cliffTimeUnit,
        cliff,
        handleHeadPress,
        data,
        handleAddPlan,
      ]
    );

    const PlanTableHead = useCallback(() => {
      return <ProgramTableHead rows={rows} sortedValue={sortedValueActive} setSortedValue={setSortedValueActive} />;
    }, [sortedValueActive, rows]);

    const PlanTableData = useCallback(() => {
      return (
        <ProgramTableContent
          programID={programID}
          data={sortedActivePlansTableData}
          isOneOff={isOneOffIndividualPlan}
        />
      );
    }, [isOneOffIndividualPlan, programID, sortedActivePlansTableData]);

    return (
      <CollapsibleContainer
        Header={isOneOffIndividualPlan ? OneOffPlansHeaderInfo : ProgramHeaderInfo}
        defaultOpen={defaultOpen}
        className={classes.programContainer}
      >
        <div className="program-table-header">
          <>
            {!R.isEmpty(data) ? (
              <div className={classes.table}>
                <Table headComponent={<PlanTableHead />} bodyComponent={<PlanTableData />} />
              </div>
            ) : (
              <div className={cn(classes.empty)}>
                <div className={classes.infoBlock}>
                  <div className={classes.icon}>
                    <InformationCircleIcon />
                  </div>
                  {status === PlanStatuses.draft ? (
                    <>
                      <Ui.xs>{t("ownershipPrograms.draftPlanPart1")}</Ui.xs>
                      <Link to={getEMPath(["plans", "poolsAndPrograms"])}>
                        <Ui.xs className={classes.link}>{t("ownershipPrograms.draftPlanPart2")}</Ui.xs>
                      </Link>
                      <Ui.xs>{t("ownershipPrograms.draftPlanPart3")}</Ui.xs>
                    </>
                  ) : (
                    <Ui.xs>{t("ownershipPrograms.noOwnershipPlans")}</Ui.xs>
                  )}
                </div>
              </div>
            )}
          </>
        </div>
      </CollapsibleContainer>
    );
  }
);

ProgramContainer.displayName = "Single Program Container";

export default ProgramContainer;
