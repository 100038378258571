import { ChangeEvent, FC, Fragment, useCallback, useEffect } from "react";
import FormGroup, { FormGroupProps } from "react-bootstrap/FormGroup";
import { useFormikContext } from "formik";

import Check from "common/components/atoms/Checks/Check";
import Helper from "common/components/atoms/Helper/Helper";
import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import { ShareClassFormData } from "common/shareClass/types";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

const t = createTranslation(TranslationNS.common, "shareClass.components.shareClassForm.shareClassTypeField");

interface ShareClassTypeFieldProps extends FormGroupProps {
  changeCallback?: (value: number | string) => void;
}
const ShareClassTypeField: FC<ShareClassTypeFieldProps> = (props) => {
  const { changeCallback } = props;
  const shareClassTypesState = useStoreState((state) => state.common.dropdowns?.shareClassTypes || []);

  const { values, setFieldValue } = useFormikContext<Pick<ShareClassFormData, "shareClassTypeId">>();

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setFieldValue(e.target.name, +e.target.value);
      if (changeCallback) {
        changeCallback(+e.target.value);
      }
    },
    [changeCallback, setFieldValue]
  );

  useEffect(() => {
    if (changeCallback && shareClassTypesState.length && !values.shareClassTypeId) {
      setFieldValue("shareClassTypeId", shareClassTypesState[0]?.id);
      if (changeCallback) {
        changeCallback(shareClassTypesState[0]?.id);
      }
    }
  }, [shareClassTypesState, setFieldValue, changeCallback, values.shareClassTypeId]);

  return (
    <FormGroup controlId="shareClassTypeId" {...props}>
      <Helper>
        <InputHeader label={t("label")} />
        {shareClassTypesState.map((type) => (
          <Fragment key={type.id}>
            <Check
              key={type.id}
              type="radio"
              label={type.name}
              value={type.id}
              checked={+values.shareClassTypeId === type.id}
              onChange={handleChange}
              name="shareClassTypeId"
              description={type.description}
            />
          </Fragment>
        ))}
      </Helper>
    </FormGroup>
  );
};

export default ShareClassTypeField;
