import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik, FormikConfig } from "formik";

import { getEMPath } from "app/Router/RouterHelper";
import { LoaderContainer } from "common/components/atoms";
import TableBrowserStorage, { useTableBrowserStorage } from "common/components/atoms/ImportTable/TableBrowserStorage";
import { BreggCompanyShareholders, OnboardingTransactionGetDTO } from "store/modelTypes";
import { useStoreActions, useStoreState } from "store/store";

import OwnershipActions from "../import-ownership/OwnershipActions/OwnershipActions";
import useImportOwnershipForm, { OwnershipFormValues } from "../import-ownership/useImportOwnershipForm";
import useOnboardCompanyIntegration from "../useOnboardCompanyIntegration";
import classes from "./ImportShareholders.module.scss";
import ShareholdersTable from "./ShareholdersTable/ShareholdersTable";
import useImportShareholders from "./useImportShareholders";

const ImportShareholdersForm: FC = () => {
  const navigate = useNavigate();
  const { companyId } = useParams<{ companyId?: string }>();

  const [completedOnboarding, setCompletedOnboarding] = useState(false);

  const company = useStoreState((state) => state.companyOnboardingModel.company);
  const shareholders = useStoreState((state) => state.companyOnboardingModel.shareholders);
  const loading = useStoreState((state) => state.companyOnboardingModel.isGetCompanyLoading);

  const { getShareholdersThunk, setShareholdersThunk } = useStoreActions((actions) => actions.companyOnboardingModel);

  const { removeImportTable } = useTableBrowserStorage<BreggCompanyShareholders[]>();

  useOnboardCompanyIntegration(false);

  const { shareholdersData, setShareholdersData } = useImportShareholders();

  const { validationSchema, initialValues } = useImportOwnershipForm(
    shareholdersData,
    false,
    company?.foundingDate,
    company?.shareClasses as OnboardingTransactionGetDTO["shareClasses"]
  );

  const handleLoadLoadData = useCallback(
    (data: BreggCompanyShareholders[]) => {
      setShareholdersData(data);
    },
    [setShareholdersData]
  );
  const handleLoadExcelData = useCallback(
    (data: BreggCompanyShareholders[]) => {
      setShareholdersData((prev) => [...prev, ...data]);
    },
    [setShareholdersData]
  );

  const handleSubmit = useCallback<FormikConfig<OwnershipFormValues[]>["onSubmit"]>(
    async (values) => {
      try {
        const existedId = values
          .filter((shareholder) => shareholder.issuedShareId)
          .map((shareholder) => shareholder.issuedShareId);

        const issuedShareIdToDelete = (shareholders || [])
          .filter((shareholder) => !existedId.includes(shareholder.issuedShareId))
          .map((shareholder) => shareholder.issuedShareId) as number[];

        const data = {
          companyId: Number(companyId),
          completedOnboarding,
          issuedShareIdToDelete,
          shareholders: values.map((shareholder) => ({
            ...shareholder,
            isCompanyOwned: Boolean(shareholder.isCompanyOwned),
            countryId: shareholder.countryId || null,
            stakeholderId: shareholder.stakeholderId || null,
          })),
        };
        await setShareholdersThunk(data);
        removeImportTable();

        navigate(getEMPath(["ownership", "capTable"], { companyId }));
      } catch (e) {
        console.log(e);
      }
    },
    [companyId, completedOnboarding, navigate, removeImportTable, shareholders, setShareholdersThunk]
  );

  useEffect(() => {
    if (companyId) {
      getShareholdersThunk(Number(companyId)).catch((e) => {
        console.log(e);
      });
    }
  }, [companyId, getShareholdersThunk]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <div className={classes.wrap}>
        <OwnershipActions handleImportData={handleLoadExcelData} setCompletedOnboarding={setCompletedOnboarding} />
        <LoaderContainer loading={loading}>
          <ShareholdersTable handleLoadData={handleLoadLoadData} />
        </LoaderContainer>
      </div>
    </Formik>
  );
};

const ImportShareholders: FC = () => {
  const { companyId } = useParams<{ companyId?: string }>();

  return (
    <TableBrowserStorage tableName={`shareholders-${companyId}`}>
      <ImportShareholdersForm />
    </TableBrowserStorage>
  );
};

export default ImportShareholders;
