import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as R from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import Tag from "common/components/atoms/Tag/Tag";
import withAgreements from "common/HOC/withAgreements";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { useStoreActions, useStoreState } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import CompanyManagePlansBody from "./components/CompanyManagePlansBody";
import ManagePlanActions from "./components/ManagePlanActions/ManagePlanActions";
import NoManagePlansData from "./components/NoManagePlansData/NoManagePlansData";
import PlansPrograms from "./components/PlansPrograms/PlansPrograms";
import classes from "./manage-plans.module.scss";
import ManagePlansContext from "./managePlansContext";

const [t, tCommon] = [
  createTranslation(TranslationNS.pages, "company.ownershipPlans"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const ManagePlans: FC = () => {
  useDocumentTitleUpdate(t("title"));

  const { companyId } = useParams();

  const ownershipPlans = useStoreState((state) => state.company.ownershipPlans);
  const isLoading = useStoreState((state) => state.company.isLoading);
  const { getOwnershipPlansThunk } = useStoreActions((actions) => actions.company);

  const isGetPlanLoading = useStoreState((state) => state.planModel.isGetPlanLoading);

  const poolsAndProgramsFeature = useFeatures(FEATURES.poolsAndPrograms);

  const isDataMissing =
    ownershipPlans &&
    R.isEmpty(ownershipPlans.oneOffPlans) &&
    R.isEmpty(ownershipPlans.ownershipPrograms) &&
    R.isEmpty(ownershipPlans.pools);

  useEffect(() => {
    if (companyId) {
      getOwnershipPlansThunk(+companyId);
    }
  }, [companyId, getOwnershipPlansThunk]);

  return (
    <>
      <PageContent className={classes.managePlans} data-testid="company-manage-plans-page">
        <PageContent.Header>
          <div className="d-flex  align-items-center">
            <PageContent.Header.Title className="me-2">{t("title")}</PageContent.Header.Title>
            {!poolsAndProgramsFeature.hasFullAccess && <Tag variant="access">{tCommon("viewOnly")}</Tag>}
          </div>
        </PageContent.Header>
        <LoaderContainer loading={isLoading}>
          {!isDataMissing ? <CompanyManagePlansBody isLoading={isGetPlanLoading} /> : <NoManagePlansData />}
          <div className="mt-4" />
          <PlansPrograms isOneOffIndividualPlan />
        </LoaderContainer>
      </PageContent>
      <ManagePlanActions />
    </>
  );
};

const CompanyManagePlansPage: FC = () => {
  return (
    <ManagePlansContext.Provider>
      <ManagePlans />
    </ManagePlansContext.Provider>
  );
};

export default withAgreements(CompanyManagePlansPage, { RSAAgreement: true, SOAgreement: true });
