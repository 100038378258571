import { FC, useState } from "react";

import { Button, ModalInfo, P } from "common/components/atoms";
import { createTranslation, TranslationNS } from "translation";

type PropsTypes = {
  transactionId: number;
  onSubmit: (transactionId: number, isRollback?: boolean) => Promise<boolean>;
  onClose: () => void;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.deleteTransactionModal");

const DeletePendingTransactionModal: FC<PropsTypes> = ({ transactionId, onClose, onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    await onSubmit(transactionId);
    setIsLoading(false);
  };

  return (
    <ModalInfo show={true} header={t("title")} handleClose={isLoading ? undefined : onClose}>
      <P.m className="mb-3">
        {t.el("areYouSure", {
          components: [<br key={0} />],
        })}
      </P.m>
      <div className="d-flex justify-content-center">
        <Button type="submit" isLoading={isLoading} onClick={handleSubmit} className="me-2">
          {t("confirmBtn")}
        </Button>
        <Button variant="secondary" isDisabled={isLoading} onClick={onClose}>
          {t("cancelBtn")}
        </Button>
      </div>
    </ModalInfo>
  );
};

export default DeletePendingTransactionModal;
