import { FC, useMemo } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { isNil } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import NoAccess from "common/access-control/no-access/NoAccess";
import NoSubscription from "common/access-control/no-subscription/NoSubscription";
import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import { useStoreState } from "store/store";

// Access Middleware for Equity Management
const AccessMiddlewareEM: FC = () => {
  const { user } = useStoreState((state) => state.account);
  const { companyId } = useParams<{ companyId: string }>();
  const { pathname } = useLocation();

  const { subscription } = useStripeSubscription(Number(companyId));
  const isSubscriptionActive = subscription?.hasSubscription && subscription?.hasFullAccess;

  const manageAccess = useMemo(() => {
    return {
      [getEMPath(["plans", "poolsAndPrograms"], { companyId })]: FEATURES.poolsAndPrograms,
      [getEMPath(["plans", "agreements"], { companyId })]: FEATURES.managePlans,
      [getEMPath(["plans", "exercising"], { companyId })]: FEATURES.exercise,
      [getEMPath(["ownership", "capTable"], { companyId })]: FEATURES.capTable,
      [getEMPath(["ownership", "financialInstruments"], { companyId })]: FEATURES.issueEquity,
      [getEMPath(["ownership", "transactions"], { companyId })]: FEATURES.transactions,
      [getEMPath(["ownership", "valuation"], { companyId })]: FEATURES.valuation,
      [getEMPath(["ownership", "shareClasses"], { companyId })]: FEATURES.shareClasses,
      [getEMPath(["ownership", "stakeholdersManagement"], { companyId })]: FEATURES.stakeholdersManagement,
      [getEMPath(["ownership", "documents"], { companyId })]: FEATURES.documents,
      [getEMPath(["gettingStartedGuide"], { companyId })]: FEATURES.gettingStartedGuide,
      [getEMPath(["createPool", "basic"], { companyId })]: FEATURES.poolsAndPrograms,
      [getEMPath(["createProgram", "main"], { companyId })]: FEATURES.poolsAndPrograms,
      [getEMPath(["createPlan", "start"], { companyId })]: FEATURES.managePlans,
      [getEMPath(["createOneOffPlan", "planReceiver"], { companyId })]: FEATURES.managePlans,
      [getEMPath(["settings", "accessControl"], { companyId })]: FEATURES.userAccessManagement,
      [getEMPath(["settings", "bankDetails"], { companyId })]: FEATURES.bankInformation,
      [getEMPath(["settings", "companyInformation"])]: FEATURES.companyInformation,
    };
  }, [companyId]);

  const subscriptionAccess = useMemo(() => {
    return {
      [getEMPath(["plans", "poolsAndPrograms"], { companyId })]: true,
      [getEMPath(["plans", "agreements"], { companyId })]: true,
      [getEMPath(["plans", "exercising"], { companyId })]: true,
      [getEMPath(["ownership", "financialInstruments"], { companyId })]: true,
      [getEMPath(["ownership", "documents"], { companyId })]: true,
      [getEMPath(["createPool", "basic"], { companyId })]: true,
      [getEMPath(["createProgram", "main"], { companyId })]: true,
      [getEMPath(["createPlan", "start"], { companyId })]: true,
      [getEMPath(["createOneOffPlan", "planReceiver"], { companyId })]: true,
    };
  }, [companyId]);

  const accessKey = manageAccess[pathname.replace(/\/$/, "")];
  const features = useFeatures(accessKey);

  if (user?.isSysAdmin) return <Outlet />;

  if (subscriptionAccess[pathname.replace(/\/$/, "")] && !isNil(subscription) && !isSubscriptionActive) {
    return <NoSubscription />;
  }

  if (accessKey && !features.hasFullAccess && !features.hasViewAccess) {
    return <NoAccess />;
  }

  return <Outlet />;
};

export default AccessMiddlewareEM;
