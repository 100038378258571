import { forwardRef, MouseEvent, useCallback } from "react";
import { AccordionEventKey } from "react-bootstrap/AccordionContext";
import cn from "classnames";
import * as R from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import Button from "common/components/atoms/Button/Button";
import { Ui } from "common/components/atoms/Typography";
import { ChevronDownIcon, PlusIcon } from "common/icons/svg";
import { OwnershipProgramTableData } from "common/types/Collapsible.types";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ProgramContainer.module.scss";

const t = createTranslation(TranslationNS.pages, "company.ownershipPlans");
const tCommon = createTranslation(TranslationNS.common, "noAccess");

type OneOffPlansHeaderProps = {
  onHeadPress?: () => void;
  onClick: (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => void;
  data: OwnershipProgramTableData[];
  activeEventKey?: AccordionEventKey;
  handleAddPlan?: () => void;
};
const OneOffPlansHeader = forwardRef<HTMLDivElement, OneOffPlansHeaderProps>((props, ref) => {
  const { onHeadPress, onClick, data, activeEventKey, handleAddPlan } = props;
  const { hasFullAccess } = useFeatures(FEATURES.managePlans);

  const handleClick = useCallback(
    (e: MouseEvent<HTMLDivElement, globalThis.MouseEvent>) => {
      onClick(e);
      if (activeEventKey == undefined && activeEventKey == null) {
        onHeadPress?.();
      }
    },
    [activeEventKey, onClick, onHeadPress]
  );
  return (
    <div
      ref={ref}
      className={cn("d-flex justify-content-between align-items-center", classes["program-header"])}
      onClick={handleClick}
    >
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center">
          <Ui.xl bold>{t("oneOffPlans.title")}</Ui.xl>
          <div className={classes["rounded-container"]}>
            <Ui.xs>{R.defaultTo(0, R.length(data))}</Ui.xs>
          </div>
        </div>

        <Ui.xs
          style={{
            color: scssVariables.foregroundMedium,
          }}
        >
          {t("oneOffPlans.description")}
        </Ui.xs>
      </div>

      <div className="d-flex">
        <div className="d-flex flex-column">
          <Button
            size="s"
            isOnlyIcon
            variant="primary"
            data-testid="event-button"
            onClick={handleAddPlan}
            isDisabled={!hasFullAccess}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
            className={classes["add-btn"]}
          >
            <PlusIcon />
          </Button>
        </div>
        <div className="ms-2 d-flex flex-column justify-content-center">
          <ChevronDownIcon
            fontSize={24}
            color={scssVariables.foregroundLow}
            direction={activeEventKey ? "bottom" : "left"}
          />
        </div>
      </div>
    </div>
  );
});

export default OneOffPlansHeader;
