import { useMemo } from "react";
import { defaultTo } from "ramda";
import * as Yup from "yup";

import { OnboardingTransaction, OnboardingTransactionGetDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import { TransactionTypesIds } from "../../../equity-management/ownership/transactions/types";

const t = createTranslation(TranslationNS.validation);

export type ImportTransactionFormValues = {
  transactionDate: string;
  transactionType: TransactionTypesIds | 0;
  numberOfShares: number | undefined;
  sharePrice: number | undefined;
  shareClassName: string;
  receivingShareholderId: number;
  sourceShareholderId: number;
  transactionId?: number | null;
};

export const fields = {
  transactionDate: "transactionDate",
  transactionType: "transactionType",
  numberOfShares: "numberOfShares",
  sharePrice: "sharePrice",
  shareClassName: "shareClassName",
  receivingShareholderId: "receivingShareholderId",
  sourceShareholderId: "sourceShareholderId",
  transactionId: "transactionId",
  total: "total",
} as const;

export const initValues = (shareClasses?: OnboardingTransactionGetDTO["shareClasses"]) => {
  const selectedShareClass = shareClasses?.length === 1 ? shareClasses[0].name : "";

  return {
    [fields.transactionDate]: "",
    [fields.transactionType]: 0,
    [fields.numberOfShares]: undefined,
    [fields.sharePrice]: undefined,
    [fields.shareClassName]: selectedShareClass,
    [fields.receivingShareholderId]: -1,
    [fields.sourceShareholderId]: -1,
    [fields.transactionId]: null,
  } as ImportTransactionFormValues;
};

const useImportTransactionForm = (
  transactions: OnboardingTransaction[],
  transactionTypes?: OnboardingTransactionGetDTO["transactionTypes"],
  shareClasses?: OnboardingTransactionGetDTO["shareClasses"]
) => {
  const initialValues = useMemo<ImportTransactionFormValues[]>(() => {
    if (transactions.length > 0) {
      const hasFromShareholder =
        transactionTypes?.filter((type) => type?.hasFromShareholder).map((stakeholder) => stakeholder?.id) || [];

      return transactions.map((transaction) => {
        const isStakeholder = hasFromShareholder?.includes(transaction.transactionType);

        const defaultValue = !isStakeholder ? transaction.sourceShareholderId || 1 : -1;
        const selectedShareClass = shareClasses?.length === 1 ? shareClasses[0].name : transaction.shareClassName;

        return {
          [fields.transactionDate]: defaultTo("", transaction.transactionDate),
          [fields.transactionType]: defaultTo(0, transaction.transactionType),
          [fields.numberOfShares]: defaultTo(undefined, transaction.numberOfShares),
          [fields.sharePrice]: defaultTo(undefined, transaction?.sharePrice),
          [fields.shareClassName]: defaultTo("", selectedShareClass),
          [fields.receivingShareholderId]: defaultTo(0, transaction.receivingShareholderId),
          [fields.sourceShareholderId]: defaultTo(defaultValue, transaction.sourceShareholderId),
          [fields.transactionId]: defaultTo(null, transaction.transactionId),
        };
      });
    }
    return [initValues(shareClasses)];
  }, [shareClasses, transactionTypes, transactions]);

  const validationSchema = useMemo(() => {
    const objectSchema = Yup.object().shape({
      [fields.transactionDate]: Yup.string().required(t("required")),
      [fields.numberOfShares]: Yup.number()
        .nullable(true)
        .integer(t("integer"))
        .min(1, t("minNumber", { number: 1 }))
        .required(t("required")),
      [fields.sharePrice]: Yup.number()
        .nullable(true)
        .min(0.000001, t("minNumber", { number: "0,000001" }))
        .required(t("required")),
      [fields.shareClassName]: Yup.string().required(t("required")),
      [fields.receivingShareholderId]: Yup.number().min(0, t("required")).required(t("required")),
      [fields.sourceShareholderId]: Yup.number().min(0, t("required")).required(t("required")),
    });

    return Yup.array().of(objectSchema);
  }, []);

  return { validationSchema, initialValues };
};

export default useImportTransactionForm;
