import axios from "axios";
import { action, thunk } from "easy-peasy";

import { notify } from "../common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "../translation";
import {
  GetInvitedStakeholdersIndicatorDTO,
  GetSuggestedStakeholdersDTO,
  StakeholderInvitationModel,
} from "./modelTypes";

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.invitationModal");

export const stakeholderInvitationModel: StakeholderInvitationModel = {
  // Invite modal

  isInviteModalOpen: false,
  inviteModalType: "your",
  isMultipleInviteModalOpen: false,
  stakeholderToInvite: undefined,

  openInviteModal: action((state, payload) => {
    state.isInviteModalOpen = true;
    state.stakeholderToInvite = payload;
  }),

  closeInviteModal: action((state) => {
    state.isInviteModalOpen = false;
  }),

  removeStakeholderToInvite: action((state) => {
    state.stakeholderToInvite = undefined;
  }),

  openMultipleInviteModal: action((state, payload) => {
    state.inviteModalType = payload;
    state.isMultipleInviteModalOpen = true;
  }),

  closeMultipleInviteModal: action((state) => {
    state.isMultipleInviteModalOpen = false;
  }),

  inviteStakeholdersThunk: thunk(async (actions, payload) => {
    try {
      const response = await axios.post("api/ownership/stakeholder/invite", payload.data);

      if (response.status === 200) {
        notify(t("singleInviteSuccessToast"), true, "success");

        if (payload.onClose) payload.onClose();

        actions.getInvitedStakeholdersIndicatorData(payload.companyId);
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  }),

  // Invitation indicator
  totalStakeholders: 100, // needed for proper loading indication
  activeStakeholders: 99,
  setStakeholdersIndicatorData: action((state, payload) => {
    state.totalStakeholders = payload.totalStakeholders;
    state.activeStakeholders = payload.activeStakeholders;
  }),
  getInvitedStakeholdersIndicatorData: thunk(async (actions, companyId) => {
    try {
      const response = await axios.get<GetInvitedStakeholdersIndicatorDTO>(
        `/api/ownership/stakeholder/invitation-summary/${companyId}`
      );

      if (response.status === 200) {
        actions.setStakeholdersIndicatorData(response.data);
        return true;
      }
    } catch (e) {
      actions.setStakeholdersIndicatorData({ totalStakeholders: 0, activeStakeholders: 0 });
      console.log(e);
    }
  }),

  // Other
  reinviteStakeholderThunk: thunk(async (_, payload) => {
    try {
      const response = await axios.post("api/ownership/stakeholder/resend-invite", {
        stakeholderId: payload,
      });

      if (response.status === 200) {
        notify(t("singleInviteSuccessToast"), true, "success");
      }

      return true;
    } catch (e) {
      console.log(e);
    }
  }),

  getSuggestedStakeholdersThunk: thunk(async (_, companyId) => {
    try {
      const response = await axios.get<GetSuggestedStakeholdersDTO>(
        `api/ownership/stakeholder/suggested-access-roles/${companyId}`
      );

      if (response.status === 200) {
        return response.data;
      }
      return [];
    } catch (e) {
      console.log(e);
      return [];
    }
  }),
};
