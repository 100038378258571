import { action } from "easy-peasy";

import { ActiveCompanyModel } from "./modelTypes/activeCompanyModel.types";

export const activeCompanyModel: ActiveCompanyModel = {
  companyId: null,
  // actions
  setCompanyId: action((state, payload) => {
    state.companyId = payload;
  }),
};
