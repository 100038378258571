import { ChangeEvent, FC, useCallback, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Formik } from "formik";
import * as R from "ramda";
import { defaultTo, omit } from "ramda";
import * as Yup from "yup";

import { getEMPath } from "app/Router/RouterHelper";
import { DatePicker, Dropdown, H, Helper, P, TextArea, TextField } from "common/components/atoms";
import { createDateString } from "common/components/atoms/DatePicker/DatePicker";
import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import DocumentUploadWithStatusesSignatureEmailInvitation from "common/components/molecules/DocumentUploadWithStatusesSignatureEmailInvitation/DocumentUploadWithStatusesSignatureEmailInvitation";
import { DocumentStatusEnum } from "common/enums/enum";
import useCurrency from "common/hooks/useCurrency";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { ArrowRightIcon } from "common/icons/svg";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import { scssVariables } from "common/utils/constants";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { useTransactionsService } from "../../../../../transactions-service";
import { CapitalIncreaseFormGeneralPostDTO, CapitalIncreaseTransaction } from "../../../../../types";
import { BuySellFields } from "../../../buy-sell/form-fields";
import { documentationDetailsValidationSchema } from "../../../form-sections/documentation-details/validation";
import { IssueSharesFields } from "../../../issue-shares/form-fields";
import CapitalIncreaseContext from "../../capital-increase-context";
import { CapitalIncreaseTransactionsFields } from "../capital-increase-transactions-table/form-fields";
import { CapitalIncreaseGeneralFields } from "./form-fields";
import { capitalIncreaseValidationSchema } from "./validation";

const t = createTranslation(TranslationNS.pages, "company.transactions.capitalIncreaseForm.general");
const tValidation = createTranslation(TranslationNS.validation);

const SHARE_ISSUES_EVENT_TYPE_ID = 2;

const CapitalIncreaseGeneral: FC = () => {
  const navigate = useNavigate();
  const { companyId = "0" } = useParams<{ companyId: string; transactionId: string }>();
  const currency = useCurrency();

  const { isLoading, currentBundle, minTransactionDate, defaultEventTypeId, defaultShareClassId } =
    CapitalIncreaseContext.useStoreState((state) => state);
  const { setIsLoading, setCurrentBundle, setStep, setDefaultEventTypeId, setDefaultShareClassId } =
    CapitalIncreaseContext.useStoreActions((actions) => actions);
  const { postCapitalIncreaseGeneralData, getCapitalIncreaseWizardData } = useTransactionsService(companyId);
  const shareClasses = useStoreState((state) => state.companyOnboardingModel?.transaction?.shareClasses);
  const transactionTypesIssuing = useStoreState((state) => state.common.dropdowns)?.transactionTypesIssuing;
  const { getTransactionsThunk } = useStoreActions((actions) => actions.companyOnboardingModel);

  // Remove share issue from dropdown options list
  const eventTypesDropdownOptions = useMemo(
    () => defaultTo([], transactionTypesIssuing).filter((el) => el.id !== SHARE_ISSUES_EVENT_TYPE_ID),
    [transactionTypesIssuing]
  );

  const fetchDropdownsData = useCallback(async () => {
    setIsLoading(true);
    const response = await getTransactionsThunk(Number(companyId)).catch((e) => {
      console.error(e);
    });

    if (response?.status === 200) {
      if (response.data.shareClasses?.length === 1) {
        setDefaultShareClassId(response.data.shareClasses[0].id);
      }
    }

    setIsLoading(false);
  }, [companyId, getTransactionsThunk, setDefaultShareClassId, setIsLoading]);

  useEffect(() => {
    setStep(1);
    fetchDropdownsData();
  }, [fetchDropdownsData, setStep]);

  const handleSubmit = useCallback(
    async (values: typeof CapitalIncreaseGeneralFields) => {
      setIsLoading(true);

      const formData = new FormData();

      Object.values(CapitalIncreaseGeneralFields).forEach((key) => {
        const formValue = values[key];

        if (!formValue) return;

        if (key === BuySellFields.documents) {
          if (!R.isEmpty(formValue)) {
            (values as any).documents.forEach((el: any) => formData.append("FilesData.Files", el));
          }
        } else {
          formData.append(key, String(formValue).trim());
        }
      });

      const createdBundle = await postCapitalIncreaseGeneralData(formData);

      if (createdBundle) {
        notify(t("notifyCreated"), true, "success");
        // TEMPORARY. LK Should provide all date in POST response
        // @ts-ignore
        const temporaryResponse = await getCapitalIncreaseWizardData(createdBundle.transactionBundleId);

        // replace with "createdBundle"
        if (temporaryResponse) {
          setCurrentBundle(temporaryResponse);
          setIsLoading(false);
          setStep(2);

          navigate(
            getEMPath(["createCapitalIncrease", "transactions"], {
              companyId,
              transactionId: String(temporaryResponse.transactionBundleId),
            })
          );
        }
      }
    },
    [
      companyId,
      getCapitalIncreaseWizardData,
      navigate,
      postCapitalIncreaseGeneralData,
      setCurrentBundle,
      setIsLoading,
      setStep,
    ]
  );
  const oldestTransactionDate: Date | undefined = useMemo(() => {
    const date = currentBundle.transactions.reduce(
      (smallestDate: Date | undefined, transaction: CapitalIncreaseTransaction): Date => {
        if (!smallestDate) {
          return new Date(transaction.transactionDate);
        }

        if (new Date(transaction.transactionDate) > smallestDate) {
          return new Date(transaction.transactionDate);
        }

        return smallestDate;
      },
      minTransactionDate
    );

    if (date) {
      return date;
    }

    return undefined;
  }, [currentBundle.transactions, minTransactionDate]);

  const validationSchema = useMemo(() => {
    return Yup.object().shape({
      ...capitalIncreaseValidationSchema(tValidation),
      ...documentationDetailsValidationSchema(tValidation),
    });
  }, []);

  const initialTransactedAt = useMemo(() => {
    if (!minTransactionDate) {
      return currentBundle.transactedAt;
    }

    if (new Date(currentBundle.transactedAt) < minTransactionDate) {
      return createDateString(minTransactionDate, true);
    }

    return currentBundle.transactedAt;
  }, [currentBundle.transactedAt, minTransactionDate]);

  const initialValues: CapitalIncreaseFormGeneralPostDTO = useMemo(() => {
    return {
      companyId: Number(companyId),
      // TODO figure out how to put documents here
      // TODO not provided in array from API yet
      documents: [],
      transactedAt: initialTransactedAt,
      description: currentBundle?.description || "",
      documentStatusId: DocumentStatusEnum.REVIEW_LATER,
      ...omit(
        [CapitalIncreaseTransactionsFields.transactions, "documentFiles", "description", "transactedAt"],
        currentBundle
      ),
    };
  }, [companyId, currentBundle, initialTransactedAt]);

  return (
    <WizardContent.Content step={1}>
      <H.xs>{t("title")}</H.xs>

      <Formik
        enableReinitialize
        initialValues={initialValues as any}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, handleChange, setFieldValue, handleSubmit }) => {
          const handleDocumentChange = (data: FilesDataMultiple) => {
            setFieldValue(IssueSharesFields.documents, data.newFiles);
          };

          return (
            <div>
              <ToastFormikValidator />
              <P.m className="mt-5 mb-4">{t("paragraph")}</P.m>
              <div className="pb-4 mb-5" style={{ borderBottom: `1px solid ${scssVariables.strokeMedium}` }}>
                <Helper>
                  <div className="d-flex justify-content-between gap-4 mb-2">
                    <div className="w-50">
                      <Helper.Question
                        questionId={CapitalIncreaseGeneralFields.eventName}
                        className="w-100 mb-2"
                        questionMarkClassName="mt-3"
                      >
                        <TextField
                          className="w-100"
                          label={t("eventName")}
                          name={CapitalIncreaseGeneralFields.eventName}
                          value={values.eventName}
                          isTouched={touched.eventName}
                          error={errors.eventName}
                          onChange={handleChange}
                          isDisabled={isLoading}
                        />
                      </Helper.Question>
                      <Helper.Answer answerId={CapitalIncreaseGeneralFields.eventName}>
                        {t("eventNameHelper")}
                      </Helper.Answer>
                    </div>
                    <div className="w-50">
                      <Helper.Question questionId="eventType" className="w-100 mb-2" questionMarkClassName="mt-3">
                        <Dropdown
                          optionsIsObject
                          className="w-100"
                          label={t("eventType")}
                          options={eventTypesDropdownOptions}
                          onChange={({ target }: ChangeEvent<HTMLInputElement>) => setDefaultEventTypeId(+target.value)}
                          selectedValue={defaultEventTypeId}
                          isOptional
                        />
                      </Helper.Question>
                      <Helper.Answer answerId="eventType">{t("eventTypeHelper")}</Helper.Answer>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between gap-4 mb-2">
                    <div className="w-50">
                      <Helper.Question
                        questionId={CapitalIncreaseGeneralFields.numberOfShares}
                        className="w-100 mb-2"
                        questionMarkClassName="mt-3"
                      >
                        <TextField
                          className="w-100"
                          label={t("totalNumber")}
                          name={CapitalIncreaseGeneralFields.numberOfShares}
                          value={values.numberOfShares}
                          isTouched={touched.numberOfShares}
                          error={errors.numberOfShares}
                          onChange={handleChange}
                          type="number"
                          isDisabled={isLoading}
                        />
                      </Helper.Question>
                      <Helper.Answer answerId={CapitalIncreaseGeneralFields.numberOfShares}>
                        {t("totalNumberHelper")}
                      </Helper.Answer>
                    </div>
                    <div className="w-50">
                      <Helper.Question
                        questionId={CapitalIncreaseGeneralFields.sharePrice}
                        className="w-100 mb-2"
                        questionMarkClassName="mt-3"
                      >
                        <TextField
                          className="w-100"
                          label={t("sharePrice")}
                          name={CapitalIncreaseGeneralFields.sharePrice}
                          value={values.sharePrice}
                          isTouched={touched.sharePrice}
                          error={errors.sharePrice}
                          iconRight={currency.formattedCurrency}
                          onChange={handleChange}
                          type="number"
                          isDisabled={isLoading}
                        />
                      </Helper.Question>
                      <Helper.Answer answerId={CapitalIncreaseGeneralFields.sharePrice}>
                        {t("sharePriceHelper")}
                      </Helper.Answer>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between gap-4 mb-2">
                    <div className="w-50">
                      <Helper.Question questionId="shareClass" className="w-100 mb-2" questionMarkClassName="mt-3">
                        <Dropdown
                          className="w-100"
                          label={t("shareClass")}
                          options={shareClasses || []}
                          optionsIsObject
                          onChange={({ target }: ChangeEvent<HTMLInputElement>) =>
                            setDefaultShareClassId(+target.value)
                          }
                          selectedValue={defaultShareClassId}
                          isOptional
                        />
                      </Helper.Question>
                      <Helper.Answer answerId="shareClass">{t("shareClassHelper")}</Helper.Answer>
                    </div>
                    <div className="w-50">
                      <Helper.Question
                        questionId={CapitalIncreaseGeneralFields.transactedAt}
                        className="w-100 mb-2"
                        questionMarkClassName="mt-3"
                      >
                        <DatePicker
                          className="w-100"
                          label={t("transactionDate")}
                          name={CapitalIncreaseGeneralFields.transactedAt}
                          date={values.transactedAt}
                          isTouched={touched.transactedAt}
                          error={errors.transactedAt}
                          onChange={(date) => setFieldValue(CapitalIncreaseGeneralFields.transactedAt, date)}
                          isWithTimeSelect
                          isDateOnlyString
                          isDisabled={isLoading}
                          minDate={oldestTransactionDate}
                        />
                      </Helper.Question>
                      <Helper.Answer answerId={CapitalIncreaseGeneralFields.transactedAt}>
                        {t("transactionDateHelper")}
                      </Helper.Answer>
                    </div>
                  </div>
                  <TextArea
                    style={{ borderBottom: `1px solid ${scssVariables.strokeMedium}` }}
                    label={t("description")}
                    name={CapitalIncreaseGeneralFields.description}
                    value={values.description}
                    isTouched={touched.description}
                    error={errors.description}
                    placeholder={t("descriptionPlaceholder")}
                    onChange={handleChange}
                    isOptional
                    isDisabled={isLoading}
                  />
                </Helper>
              </div>
              <div className="pb-4 mb-5" style={{ borderBottom: `1px solid ${scssVariables.strokeMedium}` }}>
                <H.xxs>{t("documentation")}</H.xxs>

                <P.m className="mt-2 mb-4">{t("documentationDescription")}</P.m>

                <DocumentUploadWithStatusesSignatureEmailInvitation
                  error={errors.documentStatusId as unknown as string}
                  touched={touched.documentStatusId}
                  documentStatus={Number(values?.documentStatusId || 0)}
                  onFileUploaderChange={handleDocumentChange}
                  setDocumentStatus={(statusId) => {
                    setFieldValue(IssueSharesFields.documentStatusId, statusId);
                  }}
                  isInviteFlowDisabled={true}
                />
              </div>
              <WizardContent.Controls.ContinueButton
                className="d-flex ms-auto"
                onClick={() => handleSubmit()}
                isOnlyIcon
                isLoading={isLoading}
              >
                <ArrowRightIcon />
              </WizardContent.Controls.ContinueButton>
            </div>
          );
        }}
      </Formik>
    </WizardContent.Content>
  );
};

export default CapitalIncreaseGeneral;
