import { FC, useCallback, useMemo } from "react";
import cn from "classnames";

import { Button, H, ModalInfo, Ui } from "common/components/atoms";
import {
  ArrowUpIcon,
  CloseIcon,
  CommonFileAddIcon,
  SplitIcon,
  SynchronizeArrowsSquareIcon,
  TotalValueIcon,
} from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import AddTransactionItem from "./add-transaction-item";
import classes from "./add-transaction-modal.module.scss";

type AddTransactionModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isCompanyHasShares: boolean;
  hasPendingSplitTransaction: boolean;
  handleOpenCapitalIncrease: () => void;
  setIsBuySellFormOpen: (value: boolean) => void;
  setIsIssueSharesFormOpen: (value: boolean) => void;
  setIsChangeNominalValueFormOpen: (value: boolean) => void;
  setIsSplitFormOpen: (value: boolean) => void;
};

const t = createTranslation(TranslationNS.pages, "company.transactions.addTransaction");
const AddTransactionModal: FC<AddTransactionModalProps> = ({
  isOpen,
  onClose,
  isCompanyHasShares,
  hasPendingSplitTransaction,
  handleOpenCapitalIncrease,
  setIsBuySellFormOpen,
  setIsIssueSharesFormOpen,
  setIsChangeNominalValueFormOpen,
  setIsSplitFormOpen,
}) => {
  const suggestedItems = useMemo(() => {
    return [
      {
        title: t("menu.issueSharesSingle.title"),
        description: [t("menu.issueSharesSingle.description_1")],
        icon: <CommonFileAddIcon />,
        onClick: () => {
          setIsIssueSharesFormOpen(true);
          onClose();
        },
      },
      {
        title: t("menu.issueSharesMultiple.title"),
        description: [t("menu.issueSharesMultiple.description_1")],
        icon: <ArrowUpIcon />,
        isDisabled: hasPendingSplitTransaction,
        tooltipTitle: t("tooltipDisabled"),
        tooltipDescription: t("tooltipPendingSpit"),
        onClick: handleOpenCapitalIncrease,
      },
      {
        title: t("menu.buySell.title"),
        description: [t("menu.buySell.description_1")],
        icon: <SynchronizeArrowsSquareIcon />,
        isDisabled: !isCompanyHasShares,
        tooltipTitle: t("tooltipDisabled"),
        tooltipDescription: t("tooltipNotHaveShares"),
        onClick: () => {
          setIsBuySellFormOpen(true);
          onClose();
        },
      },
    ];
  }, [
    handleOpenCapitalIncrease,
    hasPendingSplitTransaction,
    isCompanyHasShares,
    onClose,
    setIsBuySellFormOpen,
    setIsIssueSharesFormOpen,
  ]);

  const otherItems = useMemo(() => {
    return [
      {
        title: t("menu.chaneNominalValue.title"),
        description: [t("menu.chaneNominalValue.description_1")],
        icon: <TotalValueIcon />,
        isDisabled: !isCompanyHasShares,
        tooltipTitle: t("tooltipDisabled"),
        tooltipDescription: t("tooltipNotHaveShares"),
        onClick: () => {
          setIsChangeNominalValueFormOpen(true);
          onClose();
        },
      },
      {
        title: t("menu.split.title"),
        description: [t("menu.split.description_1")],
        icon: <SplitIcon />,
        isDisabled: !isCompanyHasShares,
        tooltipTitle: t("tooltipDisabled"),
        tooltipDescription: t("tooltipNotHaveShares"),
        onClick: () => {
          setIsSplitFormOpen(true);
          onClose();
        },
      },
    ];
  }, [isCompanyHasShares, onClose, setIsChangeNominalValueFormOpen, setIsSplitFormOpen]);

  const Header = useCallback(() => {
    return (
      <H.m className="d-flex justify-content-between mb-5 align-items-center">
        <span>{t("modalTitle")}</span>
        <Button size="s" isOnlyIcon variant="dark" onClick={onClose}>
          <CloseIcon />
        </Button>
      </H.m>
    );
  }, [onClose]);

  return (
    <ModalInfo size="lg" show={isOpen} onHide={onClose}>
      <div className="pt-1 ps-2 pe-2">
        <Header />
        <Ui.m className={classes.category}>{t("suggested")}</Ui.m>
        {suggestedItems.map((item, index) => (
          <AddTransactionItem
            key={index}
            title={item.title}
            description={item.description}
            icon={item.icon}
            onClick={item.onClick}
            isDisabled={item.isDisabled}
            tooltipTitle={item.tooltipTitle}
            tooltipDescription={item.tooltipDescription}
          />
        ))}
        <Ui.m className={cn(classes.category, "mt-4")}>{t("other")}</Ui.m>
        {otherItems.map((item, index) => (
          <AddTransactionItem
            key={index}
            title={item.title}
            description={item.description}
            icon={item.icon}
            onClick={item.onClick}
            isDisabled={item.isDisabled}
            tooltipTitle={item.tooltipTitle}
            tooltipDescription={item.tooltipDescription}
          />
        ))}
      </div>
    </ModalInfo>
  );
};

export default AddTransactionModal;
