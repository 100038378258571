import { useMemo } from "react";

import { useStoreState } from "../../store/store";
import { ACCESS_LEVELS, AccessLevels, FEATURES, FeaturesAccessLevel, FeaturesIds } from "./types";

export const useFeatures = (featureToCheck: FeaturesIds) => {
  const { user } = useStoreState((state) => state.account);
  const currentCompanyId = useStoreState((state) => state.activeCompanyModel.companyId);

  const currentCompany = useMemo(() => {
    return user?.companies.find((company) => company.id === currentCompanyId);
  }, [user?.companies, currentCompanyId]);

  return useMemo(() => {
    return {
      hasViewAccess: (currentCompany?.features?.[featureToCheck] || 0) >= ACCESS_LEVELS.VIEW,
      hasFullAccess: user?.isSysAdmin || (currentCompany?.features?.[featureToCheck] || 0) >= ACCESS_LEVELS.FULL,
    };
  }, [currentCompany?.features, featureToCheck, user?.isSysAdmin]);
};

// TESTS
const populateFeatures = (accessLevel: AccessLevels): FeaturesAccessLevel => {
  const features: any = {};

  for (let i = 1; i <= Object.keys(FEATURES).length; i++) {
    features[i] = accessLevel;
  }

  return features;
};

export const mockFeaturesForUser = {
  noAccess: populateFeatures(0),
  view: populateFeatures(1),
  full: populateFeatures(2),
};
