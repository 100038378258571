export type FeaturesNames =
  | "capTable"
  | "fullyDilutedCapTable"
  | "exercise"
  | "issueEquity"
  | "valuation"
  | "shareClasses"
  | "documents"
  | "managePlans"
  | "poolsAndPrograms"
  | "gettingStartedGuide"
  | "stakeholdersManagement"
  | "transactions"
  | "attract"
  | "bankInformation"
  | "userAccessManagement"
  | "acceptAgreement"
  | "ownershipOverview"
  | "companyFileDownload"
  | "companyInformation";

export const ACCESS_LEVELS = {
  NO_ACCESS: 0,
  VIEW: 1,
  FULL: 2,
} as const;

export const FEATURES: Record<FeaturesNames, number> = {
  capTable: 1,
  exercise: 2,
  issueEquity: 3,
  valuation: 4,
  shareClasses: 5,
  documents: 6,
  bankInformation: 7,
  managePlans: 8,
  attract: 9,
  fullyDilutedCapTable: 10,
  ownershipOverview: 11,
  poolsAndPrograms: 12,
  gettingStartedGuide: 13,
  companyFileDownload: 14,
  userAccessManagement: 15,
  acceptAgreement: 16,
  stakeholdersManagement: 17,
  transactions: 18,
  companyInformation: 19,
} as const;

export type FeaturesIds = (typeof FEATURES)[keyof typeof FEATURES];
export type AccessLevels = (typeof ACCESS_LEVELS)[keyof typeof ACCESS_LEVELS];
export type FeaturesAccessLevel = Record<FeaturesIds, AccessLevels>;
