import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import Highlighter from "react-highlight-words";
import cn from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, ContextMenu, ContextMenuProps } from "common/components/atoms";
import { Ui } from "common/components/atoms/Typography";
import { FilledArrowDownIcon, MenuTabBarVerticalIcon, PlusIcon, WarningIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";

import DocumentsContext, { TransactionInstrumentsDocumentsType } from "../../../DocumentsContext";
import classes from "../../CommonStyles.module.scss";
import EmptyDocumentsRow from "../../EmptyDocumentsRow";
import NestedTableRow from "./NestedTableRow";

type CommonDocumentsTableRowProps = {
  data: TransactionInstrumentsDocumentsType["transactions"][0];
};

const TransactionDocumentsTableRow: FC<CommonDocumentsTableRowProps> = ({ data }) => {
  const { hasFullAccess } = useFeatures(FEATURES.documents);

  const { searchValue } = DocumentsContext.useStoreState((state) => state);
  const { setIsEditPanelOpen, setSelectedSingleTransaction } = DocumentsContext.useStoreActions((actions) => actions);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const rowRef = useRef<HTMLTableRowElement>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);

  const items = useMemo<ContextMenuProps["items"]>(
    () => [
      {
        label: (
          <div className="d-flex align-items-center">
            <PlusIcon width={24} height={24} className="me-2" />
            <Ui.m>Upload</Ui.m>
          </div>
        ),
        key: "Upload",
        isDisabled: !hasFullAccess,
        onClick: () => {
          setSelectedSingleTransaction(data);

          setIsEditPanelOpen({
            isOpen: true,
            documentType: "transactions",
          });
        },
      },
    ],
    [data, hasFullAccess, setIsEditPanelOpen, setSelectedSingleTransaction]
  );

  const rowClickHandler = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const uploadHandler: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.stopPropagation();

      setSelectedSingleTransaction(data);

      setIsEditPanelOpen({
        isOpen: true,
        documentType: "transactions",
      });
    },
    [data, setIsEditPanelOpen, setSelectedSingleTransaction]
  );

  useEffect(() => {
    if (searchValue.trim() && !isOpen) {
      rowRef?.current?.click();
    }
  }, [data, isOpen, searchValue]);

  return (
    <>
      <motion.tr
        ref={rowRef}
        data-testid="company-documents-table-single-row-test-id"
        className={cn(classes["table-row"], {
          [classes.hovered]: isHovered,
          [classes.selected]: isOpen,
        })}
        onHoverEnd={setIsHovered.bind(null, false)}
        onHoverStart={setIsHovered.bind(null, true)}
        onClick={rowClickHandler}
      >
        <td>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center position-relative">
              <motion.div
                className="me-1"
                initial={{ transform: "rotate(0deg)" }}
                animate={{
                  transform: isOpen ? "rotate(0deg)" : "rotate(-90deg)",
                }}
              >
                <FilledArrowDownIcon color={scssVariables.foregroundLow} />
              </motion.div>

              <Ui.m className="ms-3 fw-500">
                <Highlighter
                  autoEscape={true}
                  textToHighlight={data.name}
                  searchWords={[searchValue as string]}
                  highlightClassName={classes.highligted}
                  unhighlightClassName={classes.unhighlited}
                />
              </Ui.m>

              {isHovered ? (
                <Button
                  variant="tertiary"
                  iconLeft={<PlusIcon />}
                  size="s"
                  className={classes["upload-btn"]}
                  isDisabled={!hasFullAccess}
                  onClick={uploadHandler}
                >
                  Upload
                </Button>
              ) : null}
            </div>

            {data.missingDocuments ? (
              <WarningIcon fontSize={24} color={scssVariables.warning700} className="ms-auto me-1" />
            ) : null}
          </div>
        </td>
      </motion.tr>

      <AnimatePresence>
        {isOpen &&
          (data.documents.length ? (
            data.documents.map((el, index) => <NestedTableRow key={`Inner table row index - ${index}`} data={el} />)
          ) : (
            <EmptyDocumentsRow
              isRequired
              iconClassName="ms-6"
              actions={
                <ContextMenu items={items} drop="down" className="ms-auto">
                  <Button
                    isOnlyIcon
                    variant="tertiary"
                    data-testid="plan-dropdown-btn"
                    className={classes["menu-button"]}
                  >
                    <MenuTabBarVerticalIcon />
                  </Button>
                </ContextMenu>
              }
            />
          ))}
      </AnimatePresence>
    </>
  );
};

export default TransactionDocumentsTableRow;
