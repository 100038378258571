import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import { Formik, FormikConfig } from "formik";
import { equals, isNil } from "ramda";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import LoaderContainer from "common/components/atoms/LoaderContainer/LoaderContainer";
import Tag from "common/components/atoms/Tag/Tag";
import useDocumentTitleUpdate from "common/hooks/useDocumentTitleUpdate";
import useStripeSubscription from "common/hooks/useStripeSubscription";
import PageContent from "common/layout/MainLayout/PageContent/PageContent";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { TranslationNS } from "translation";
import { createTranslation } from "translation/helpers";

import CompanyBillingDetails from "./CompanyBillingDetails";
import CompanyInformationForm from "./CompanyInformationForm";
import CompanyInfoTabs from "./CompanyInfoTabs";
import useCompanyInformationForm, { FormGeneralValues } from "./useCompanyInformationForm";

type PropsTypes = {
  isTestsPurposes?: boolean;
};

const [translation, tCommon] = [
  createTranslation(TranslationNS.pages, "companyProfile.general"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const CompanyInformation: FC<PropsTypes> = ({ isTestsPurposes }) => {
  useDocumentTitleUpdate(translation("title"));

  const { companyId } = useParams<{ companyId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const { hasFullAccess } = useFeatures(FEATURES.companyInformation);
  const { subscription } = useStripeSubscription();

  const { generalInfo } = useStoreState((state) => state.company);

  const { getGeneralInfoThunk } = useStoreActions((state) => state.company);
  const { getAccountThunk } = useStoreActions((state) => state.account);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [activeTab, setActiveTab] = useState<number>(0);

  const isTabsHidden = useMemo(
    () =>
      !hasFullAccess ||
      (!isNil(subscription) && (!subscription?.hasSubscription || isNil(subscription?.stripeSubscriptionId))),
    [hasFullAccess, subscription]
  );

  const uploadFile = useCallback(
    async (logoFile?: File) => {
      if (logoFile) {
        const formData = new FormData();
        formData.append("ImageFile", logoFile);

        try {
          await axios.post(`/api/attract/logo/${generalInfo?.id}`, formData);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [generalInfo?.id]
  );

  const { initialValues, validationSchema } = useCompanyInformationForm();

  const updateCompanyProfile = useCallback(
    async (values: FormGeneralValues) => {
      try {
        const generalInfoData = {
          id: generalInfo?.id,
          ...values,
          numberOfEmployees: values.numberOfEmployees ? values.numberOfEmployees : 0,
          headquartersCountryId: values.headquartersCountryId ? values.headquartersCountryId : null,
          currencyId: values.currencyId ? values.currencyId : null,
        };

        const request = await axios.post("/api/attract/general-information", generalInfoData);

        if (request.status === 200) {
          await getAccountThunk();

          return true;
        }
      } catch (e) {
        return false;
      }
    },
    [generalInfo?.id, getAccountThunk]
  );

  const submitHandler = useCallback<FormikConfig<FormGeneralValues>["onSubmit"]>(
    async (values) => {
      await uploadFile(values.logoFile);
      const sent = await updateCompanyProfile(values);
      if (sent) {
        notify(translation("notify.changesSaved"), true, "success");
        await getGeneralInfoThunk(companyId ? +companyId : 0);
      }
    },
    [companyId, getGeneralInfoThunk, updateCompanyProfile, uploadFile]
  );

  const getData = useCallback(
    async (companyId: number) => {
      setIsLoading(true);
      await getGeneralInfoThunk(companyId);
      setIsLoading(false);
    },
    [getGeneralInfoThunk]
  );

  useEffect(() => {
    getData(companyId ? +companyId : 0).then();
  }, [companyId, getData]);

  useEffect(() => {
    if (searchParams.get("tab") === "details") {
      setActiveTab(0);
    }

    if (searchParams.get("tab") === "billing") {
      setActiveTab(1);
    }
  }, [searchParams]);

  useEffect(() => {
    if (isTabsHidden) {
      setSearchParams("tab=details");
      setActiveTab(0);
    }
  }, [isTabsHidden, setSearchParams]);

  return (
    <PageContent data-testid="company-profile-general-test-id">
      <PageContent.Header>
        <div className="d-flex align-items-center">
          <PageContent.Header.Title className="me-2">{translation("title")}</PageContent.Header.Title>
          {!hasFullAccess && <Tag>{tCommon("viewOnly")}</Tag>}
        </div>

        {/*<ViewPitchButton id={Number(companyId)} />*/}
      </PageContent.Header>

      <LoaderContainer loading={isLoading}>
        {isTabsHidden ? null : <CompanyInfoTabs activeTab={activeTab} setSelectedTab={setActiveTab} />}

        {equals(activeTab, 0) ? (
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={submitHandler}
            validationSchema={validationSchema}
          >
            <CompanyInformationForm isTestsPurposes={isTestsPurposes} hasFullAccess={hasFullAccess} />
          </Formik>
        ) : null}

        {equals(activeTab, 1) ? <CompanyBillingDetails /> : null}
      </LoaderContainer>
    </PageContent>
  );
};

export default CompanyInformation;
