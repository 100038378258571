import { useMemo, useState } from "react";
import { ColumnDef } from "@tanstack/react-table";

import { createDateString } from "common/components/atoms/DatePicker/DatePicker";
import { DateInput, SelectInput, TextInput } from "common/components/atoms/ImportTable";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { CommonObjectType, OnboardingTransactionGetDTO, ShareClassGetDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import TextareaInput from "../../../../../../../../../common/components/atoms/ImportTable/inputs/TextareaInput";
import { TransactionStatus } from "../../../../../types";
import CapitalIncreaseContext from "../../capital-increase-context";
import classes from "./import-transaction.module.scss";
import RowActions from "./row-actions/row-actions";
import ShareholderField from "./TransactionTable/components/shareholder-field";
import { fields, ImportTransactionFormValues } from "./use-import-transaction-form";

const t = createTranslation(TranslationNS.pages, "company.transactions.capitalIncreaseForm.transactions");

const useImportTransactionColumns = (
  refetchData: () => Promise<void>,
  shareClasses?: Pick<ShareClassGetDTO, "name" | "id">[],
  transactionTypes?: CommonObjectType[],
  stakeholders?: OnboardingTransactionGetDTO["stakeholders"]
) => {
  const currency = useCurrency();
  const fNumber = useFormatNumbers();

  const {
    currentBundle: { transactedAt, numberOfShares, statusId },
    minTransactionDate,
  } = CapitalIncreaseContext.useStoreState((state) => state);

  const transactionTypeWithEmpty = useMemo(() => {
    const buySellTypeId = 9;
    const shareIssuesTypeId = 2;

    return transactionTypes
      ? transactionTypes.filter((type) => ![buySellTypeId, shareIssuesTypeId].includes(type.id))
      : [];
  }, [transactionTypes]);

  const shareClassesWithEmpty = useMemo(() => {
    return shareClasses ? shareClasses : [];
  }, [shareClasses]);

  const stakeholdersWithEmpty = useMemo(() => {
    const createNew = {
      id: -2,
      name: t("createStakeholder"),
    };

    return stakeholders ? [createNew, ...stakeholders] : [createNew];
  }, [stakeholders]);

  const [createdStakeholdersId, setCreatedStakeholdersId] = useState<Record<string, number | null>>({});

  return useMemo<ColumnDef<ImportTransactionFormValues>[]>(() => {
    const cells: ColumnDef<ImportTransactionFormValues>[] = [
      {
        header: t("fields.transactionNumber"),
        accessorKey: "transactionNumber",
        cell: (props) => <div className={classes.transactionNumber}>{props.row.index + 1}</div>,
        size: 20,
        footer: () => {
          return <span>{t("total")}</span>;
        },
      },
      {
        header: t("fields.receivingShareholderId") + " *",
        accessorKey: fields.receivingShareholderId,
        cell: (props) => {
          const cellId = props?.cell?.id || "";

          const setStakeholderId = (stakeholderId: number | null) => {
            setCreatedStakeholdersId({
              ...createdStakeholdersId,
              [cellId]: stakeholderId,
            });
          };

          return (
            <ShareholderField
              rowIndex={props.row.index}
              columnId={props.column.id}
              stakeholdersOptions={stakeholdersWithEmpty}
              refetchCallback={refetchData}
              createdStakeholderId={createdStakeholdersId[cellId]}
              setCreatedStakeholderId={setStakeholderId}
              placeholder={t("notSelected")}
            />
          );
        },
        minSize: 170,
      },
      {
        header: t("fields.transactionDate") + " *",
        accessorKey: fields.transactionDate,
        cell: (props) => (
          <DateInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            placeholder="dd.mm.yyyy"
            isWithTimeSelect
            minDate={minTransactionDate ? createDateString(minTransactionDate) : undefined}
            maxDate={transactedAt}
          />
        ),
        minSize: 170,
      },
      {
        header: t("fields.transactionTypeId"),
        accessorKey: fields.transactionType,
        cell: (props) => (
          <SelectInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={transactionTypeWithEmpty}
            placeholder={t("notSelected")}
            isClearable
          />
        ),
        minSize: 240,
      },
      {
        header: t("fields.numberOfShares") + " *",
        accessorKey: fields.numberOfShares,
        cell: (props) => (
          <TextInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            className={classes.shareClassAlign}
            type="number"
            placeholder={t("fields.shares")}
          />
        ),
        minSize: 170,
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => +sum + +(row.original.numberOfShares || 0), 0);

          const displayValue = (numberOfShares || 0) - total;

          return (
            <div className={"text-end"}>
              <span>{fNumber(total, "amount")}</span>
              <span className="text-medium">{` (${fNumber(
                displayValue < 0 ? `+${displayValue}` : `-${displayValue}`,
                "amount"
              )})`}</span>
            </div>
          );
        },
        size: 100,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("fields.sharePrice") + " *",
        accessorKey: fields.sharePrice,
        cell: (props) => (
          <TextInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            className={classes.shareClassAlign}
            type="number"
            iconRight={currency.formattedCurrency}
          />
        ),
        minSize: 160,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("fields.subscription"),
        accessorKey: fields.total,
        cell: (props) => {
          const numberOfShares = (props.row.getValue(fields.numberOfShares) as number) || 0;
          const price = (props.row.getValue(fields.sharePrice) as number) || 0;

          return <div className={classes.transactionNumber}>{fNumber(numberOfShares * price, "value")}</div>;
        },
        size: 140,
        meta: {
          headClass: "text-end",
        },
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce(
              (sum, row) =>
                sum + Number(row.getValue(fields.numberOfShares) || 0) * Number(row.getValue(fields.sharePrice) || 0),
              0
            );

          return <div className={"text-end"}>{fNumber(total, "value")}</div>;
        },
      },
      {
        header: t("fields.shareClassId") + " *",
        accessorKey: fields.shareClassId,
        cell: (props) => (
          <SelectInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={shareClassesWithEmpty}
            placeholder={t("notSelected")}
          />
        ),
        minSize: 170,
      },
      {
        header: "Description",
        accessorKey: fields.description,
        cell: (props) => <TextareaInput rowIndex={props.row.index} columnId={props.column.id} />,
        minSize: 160,
        meta: {
          headClass: "text-start",
        },
      },
      {
        accessorKey: "actions",
        header: "",
        cell: ({ row, table }) => (
          <RowActions
            rowIndex={row.index}
            updateData={table.options.meta?.updateData}
            globalFilter={table.getState().globalFilter}
            isBundleConfirmed={statusId === TransactionStatus.Confirmed}
            stakeholders={stakeholders || []}
          />
        ),
        maxSize: 42,
      },
    ];

    return cells;
  }, [
    minTransactionDate,
    transactedAt,
    transactionTypeWithEmpty,
    numberOfShares,
    fNumber,
    currency.formattedCurrency,
    shareClassesWithEmpty,
    stakeholdersWithEmpty,
    refetchData,
    createdStakeholdersId,
    statusId,
    stakeholders,
  ]);
};

export default useImportTransactionColumns;
