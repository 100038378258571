import axios from "axios";
import { Action, action, createContextStore, Thunk, thunk } from "easy-peasy";

import { OwnershipProgramTableData } from "common/types/Collapsible.types";
import { PlanTerminationDetailsType } from "store/types";

import { PlanTerminationPOSTData } from "./manage-plans.types";

type PlanInfo = OwnershipProgramTableData & { programId?: number };

interface ManagePlansContextModel {
  editPlanInfo: PlanInfo | null;
  deletePlanInfo: OwnershipProgramTableData | null;
  sendInviteInfo: OwnershipProgramTableData | null;
  grantPlanInfo: OwnershipProgramTableData | null;
  terminationPlanInfo: PlanTerminationDetailsType | null;

  setEditPlanInfo: Action<this, PlanInfo | null>;
  setDeletePlanInfo: Action<this, OwnershipProgramTableData | null>;
  setSendInviteInfo: Action<this, OwnershipProgramTableData | null>;
  setGrantPlanInfo: Action<this, OwnershipProgramTableData | null>;
  setTerminationPlanInfo: Action<this, PlanTerminationDetailsType | null>;
  getPlanTerminationDetailsThunk: Thunk<this, number>;
  terminateRsaPlanThunk: Thunk<this, PlanTerminationPOSTData>;
  terminateSoPlanThunk: Thunk<this, PlanTerminationPOSTData>;
}

const ManagePlansContext = createContextStore<ManagePlansContextModel>(
  {
    editPlanInfo: null,
    deletePlanInfo: null,
    sendInviteInfo: null,
    grantPlanInfo: null,
    terminationPlanInfo: null,

    setEditPlanInfo: action((state, payload) => {
      state.editPlanInfo = payload;
    }),
    setDeletePlanInfo: action((state, payload) => {
      state.deletePlanInfo = payload;
    }),
    setSendInviteInfo: action((state, payload) => {
      state.sendInviteInfo = payload;
    }),
    setGrantPlanInfo: action((state, payload) => {
      state.grantPlanInfo = payload;
    }),
    setTerminationPlanInfo: action((state, payload) => {
      state.terminationPlanInfo = payload;
    }),

    getPlanTerminationDetailsThunk: thunk(async (actions, id) => {
      try {
        const request = await axios.get<PlanTerminationDetailsType>(`/api/ownership/plan/termination/${id}`);

        if (request.status === 200) {
          actions.setTerminationPlanInfo(request.data);
        }
      } catch (e) {
        console.warn(JSON.parse(JSON.stringify(e)));
      }
    }),
    terminateRsaPlanThunk: thunk(async (actions, data) => {
      try {
        const request = await axios.post("/api/ownership/plan/rsa/terminate", data);

        if (request.status === 200) {
          actions.setTerminationPlanInfo(null);
        }
      } catch (e) {
        console.warn(JSON.parse(JSON.stringify(e)));
      }
    }),
    terminateSoPlanThunk: thunk(async (actions, data) => {
      try {
        const request = await axios.post("/api/ownership/plan/stock-options/terminate", data);

        if (request.status === 200) {
          actions.setTerminationPlanInfo(null);
        }
      } catch (e) {
        console.warn(JSON.parse(JSON.stringify(e)));
      }
    }),
  },
  {
    name: "Manage Plans Context",
  }
);

export default ManagePlansContext;
