import { FC, Fragment, useCallback } from "react";
import { useFormikContext } from "formik";
import { isNil } from "ramda";

import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import Helper from "common/components/atoms/Helper/Helper";
import InputHeader from "common/components/atoms/input-blocks/input-header/input-header";
import { CreateProgramFormData } from "pages/equity-management/plans/wizards/create-program/useProgramForm";
import { useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { AvailableAmountAgreementsType } from "../../../../types/Common.types";

const t = createTranslation(TranslationNS.common, "program.components.programForm.vestingDetails.vestingTypeId");

const VestingTypeField: FC = () => {
  const vestingTypesStore = useStoreState((state) => state.common)?.dropdowns?.vestingTypes || [];

  const { values, setFieldValue } = useFormikContext<
    Pick<CreateProgramFormData, "vestingTypeId"> &
      Partial<Record<"subscriptionAvailable", AvailableAmountAgreementsType>>
  >();

  const isFormDisabled =
    !isNil(values.subscriptionAvailable) && !values.subscriptionAvailable.remainingAgreements;

  const handleChange = useCallback(
    (e: any) => {
      setFieldValue(e.target.name, +e.target.value);
    },
    [setFieldValue]
  );

  return (
    <>
      <Helper.Question questionId="vestingTypeId">
        <InputHeader label={t("label")}></InputHeader>
      </Helper.Question>
      <Helper.Answer
        className="mt-1 mb-1"
        answerId="vestingTypeId"
        text={t("answer")}
        linkText={t("readMore")}
        link="/"
      />
      <ChecksGroup isDisabled={isFormDisabled}>
        {vestingTypesStore.map((el) => (
          <Fragment key={el.id}>
            <Helper.Question questionId={`vestingTypeId.${el.id}`}>
              <ChecksGroup.Check
                type="radio"
                label={el.name}
                name="vestingTypeId"
                checked={values.vestingTypeId === el.id}
                value={el.id}
                onChange={handleChange}
                disabled={isFormDisabled}
              />
            </Helper.Question>
            <Helper.Answer answerId={`vestingTypeId.${el.id}`} text={el.description} />
          </Fragment>
        ))}
      </ChecksGroup>
    </>
  );
};

export default VestingTypeField;
