import { createStore, createTypedHooks, persist } from "easy-peasy";

import appModel, { AppModel } from "app/store";
import { afterSignup, AfterSignupModel } from "store/AfterSignupModel";

import { account } from "./AccountModel";
import { activeCompanyModel } from "./ActiveCompanyModel";
import { authentication, AuthModel } from "./AuthenticationModel";
import { common } from "./CommonModel";
import { company } from "./CompanyModel";
import { gettingStartedGuide, IGettingStartedGuideModel } from "./GettingStartedGuideModel";
import { investmentEntity } from "./InvestmentEntityModel";
import { invitedUser, InvitedUserModel } from "./InvitedUserModel";
import {
  AccountModel,
  CommonModel,
  CompanyOnboardingModel,
  PlanModel,
  PoolModel,
  ProgramModel,
  ShareClassModel,
  StakeholderInvitationModel,
} from "./modelTypes";
import { ActiveCompanyModel } from "./modelTypes/activeCompanyModel.types";
import { notifications } from "./NotificationModels";
import { companyOnboardingModel } from "./OnboardingCompanyModel";
import { onboarding, OnboardingModel } from "./OnboardingModel";
import { planModel } from "./PlanModel";
import { poolModel } from "./PoolModel";
import { programModel } from "./ProgramModel";
import { shareClassModel } from "./ShareClassModel";
import { stakeholderInvitationModel } from "./StakeholderInvitationModel";
import { status, StatusModel } from "./StatusModel";
import { CompanyModel, InvestmentEntityModel, NotificationModel } from "./types";

export interface StoreModel {
  app: AppModel;
  authentication: AuthModel;
  account: AccountModel;
  status: StatusModel;
  activeCompanyModel: ActiveCompanyModel;
  company: CompanyModel;
  common: CommonModel;
  investmentEntity: InvestmentEntityModel;
  onboarding: OnboardingModel;
  notifications: NotificationModel;
  invitedUser: InvitedUserModel;
  afterSignup: AfterSignupModel;
  gettingStartedGuide: IGettingStartedGuideModel;
  planModel: PlanModel;
  poolModel: PoolModel;
  programModel: ProgramModel;
  stakeholderInvitation: StakeholderInvitationModel;
  shareClassModel: ShareClassModel;
  companyOnboardingModel: CompanyOnboardingModel;
}

export const storeModal: StoreModel = {
  app: appModel,
  authentication,
  account,
  status,
  company,
  activeCompanyModel: persist(activeCompanyModel),
  common,
  investmentEntity,
  onboarding,
  invitedUser: persist(invitedUser),
  afterSignup: persist(afterSignup),
  notifications,
  gettingStartedGuide,
  planModel,
  poolModel,
  programModel,
  stakeholderInvitation: stakeholderInvitationModel,
  shareClassModel,
  companyOnboardingModel: persist(companyOnboardingModel, {
    allow: ["companyType", "integrations"],
  }),
};

const store = createStore<StoreModel>(storeModal, { name: "Global Unlisted Store" });

export const { useStoreActions, useStoreState, useStoreDispatch } = createTypedHooks<StoreModel>();

export default store;
