import { FC, Fragment, memo, useCallback, useRef } from "react";
import cn from "classnames";

import { useStoreState } from "store/store";

import ProgramContainer from "../ProgramContainer/ProgramContainer";
import classes from "./PlansPrograms.module.scss";

type PlanProgramsProps = {
  onGrantAllPlansPress?: (programId: number) => void;
  isOneOffIndividualPlan?: boolean;
};

const PlansPrograms: FC<PlanProgramsProps> = ({ onGrantAllPlansPress, isOneOffIndividualPlan = false }) => {
  const programsRefs = useRef<HTMLDivElement[]>([]);
  const oneOffPlanRef = useRef<HTMLDivElement>(null);

  const { ownershipPlans } = useStoreState((state) => state.company);

  const addToRefs = useCallback((el: HTMLDivElement) => {
    if (el && !programsRefs.current.includes(el)) {
      programsRefs.current.push(el);
    }
  }, []);

  if (isOneOffIndividualPlan && ownershipPlans) {
    return (
      <div className={cn("mb-4", classes["one-off-plans"])}>
        <ProgramContainer
          defaultOpen
          isOneOffIndividualPlan={isOneOffIndividualPlan}
          data={ownershipPlans?.oneOffPlans}
          ref={oneOffPlanRef}
        />
      </div>
    );
  }

  const plans = ownershipPlans?.ownershipPrograms?.map((el, index) => {
    const programData = el.plans;

    return (
      <Fragment key={el.id}>
        <ProgramContainer
          programID={el.id}
          title={el.name}
          cliff={el.cliff}
          data={programData}
          status={el.statusName}
          defaultOpen={index === 0}
          description={el.description}
          isOneOffIndividualPlan={isOneOffIndividualPlan}
          vestingPeriod={el.vestingPeriod}
          vestingPeriodTimeUnit={el.vestingPeriodTimeUnit}
          cliffTimeUnit={el.cliffTimeUnit}
          stockType={el.instrumentTypeName}
          stockTypeId={el.instrumentTypeId}
          ref={addToRefs}
          index={index}
          onGrantAllPlansPress={onGrantAllPlansPress}
        />

        <div className="mt-4" />
      </Fragment>
    );
  });

  return <>{plans}</>;
};

export default memo(PlansPrograms);
