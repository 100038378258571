import { FC, useCallback, useState } from "react";
import { useFormikContext } from "formik";
import { motion } from "framer-motion";

import { DeleteIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import Button from "../../Button/Button";
import { H, ModalInfo, P } from "../../index";
import SaveButton from "./SaveButton/SaveButton";
import classes from "./StickyActions.module.scss";
const t = createTranslation(TranslationNS.pages, "onboard.company.importOwnership");

type StickyActionsProps = {
  countOfActiveCheckboxes: number;
  // optional description content that will show when user hovering question mark
  descriptionContent?: string;
};

const StickyActions: FC<StickyActionsProps> = ({ countOfActiveCheckboxes, descriptionContent }) => {
  const { values, setValues } = useFormikContext<(object & { checkbox?: boolean })[]>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleManageDeleteModal = useCallback(() => {
    setShowDeleteModal((prev) => !prev);
  }, []);

  const handleDeleteRows = useCallback(() => {
    const updatedData = values.filter((item) => item.checkbox !== true);
    setValues(updatedData);
    handleManageDeleteModal();
  }, [handleManageDeleteModal, setValues, values]);

  return (
    <div className={classes.actions}>
      {!!countOfActiveCheckboxes && (
        <motion.div initial={{ opacity: 0, y: 100 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
          <Button variant="danger" iconLeft={<DeleteIcon />} onClick={handleManageDeleteModal}>
            {t("deleteRow", { count: countOfActiveCheckboxes })}
          </Button>
        </motion.div>
      )}
      <div>
        <SaveButton descriptionContent={descriptionContent} />
      </div>
      <ModalInfo
        show={showDeleteModal}
        onHide={handleManageDeleteModal}
        footer={
          <div>
            <Button variant="danger" onClick={handleDeleteRows}>
              {t("delete")}
            </Button>
            <Button onClick={handleManageDeleteModal} className="ms-2" variant="secondary">
              {t("cancel")}
            </Button>
          </div>
        }
      >
        <H.xs>{t("deleteTitle")}</H.xs>
        <P.m className="mt-3">{t("deleteConfirm", { count: countOfActiveCheckboxes })}</P.m>
      </ModalInfo>
    </div>
  );
};

export default StickyActions;
