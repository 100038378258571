import { FC, useCallback, useState } from "react";
import classNames from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Button, Tag } from "common/components/atoms";
import { StakeholderToInvite } from "store/modelTypes";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import classes from "../stakeholder-details.module.scss";
import { StakeholderInvitationStatus, StakeholderInvitationStatusIds } from "../types";

type PropsTypes = {
  stakeholder: StakeholderToInvite;
  status: StakeholderInvitationStatusIds;
  className?: string;
  handleClose: () => void;
  editButtonPress?: () => void;
};

const [t, tCommon] = [
  createTranslation(TranslationNS.common, "organisms.stakeholderDetails.details.invitation"),
  createTranslation(TranslationNS.common, "noAccess"),
];

const InviteButton: FC<PropsTypes> = ({ editButtonPress, status, stakeholder, className, handleClose }) => {
  const [isResendingInvite, setIsResendingInvite] = useState(false);
  const { hasFullAccess } = useFeatures(FEATURES.stakeholdersManagement);

  const { openInviteModal, reinviteStakeholderThunk } = useStoreActions((actions) => actions.stakeholderInvitation);

  const openInviteModalHandler = useCallback(() => {
    handleClose();
    openInviteModal(stakeholder);
  }, [handleClose, openInviteModal, stakeholder]);

  const resendInviteHandler = useCallback(async () => {
    setIsResendingInvite(true);
    await reinviteStakeholderThunk(stakeholder.stakeholderId);
    setIsResendingInvite(false);
  }, [reinviteStakeholderThunk, stakeholder.stakeholderId]);

  if (status === StakeholderInvitationStatus.active)
    return (
      <div>
        <Tag variant="complete" size="s" className={classNames("mx-auto", classes["invite-tag"])}>
          {t("registeredUser")}
        </Tag>

        {editButtonPress ? (
          <Button size="s" variant="secondary" onClick={editButtonPress} className="mt-4">
            Edit
          </Button>
        ) : null}
      </div>
    );

  if (status === StakeholderInvitationStatus.notInvited)
    return (
      <div>
        <Tag className={classNames("mb-4 mx-auto", classes["invite-tag"])} size="s" variant="draft">
          {t("notInvited")}
        </Tag>

        <div className="d-flex align-items-center justify-content-center">
          {editButtonPress ? (
            <Button size="s" variant="secondary" onClick={editButtonPress} className="me-2">
              Edit
            </Button>
          ) : null}

          <Button
            size="s"
            className={className}
            onClick={openInviteModalHandler}
            variant="secondary"
            isDisabled={!hasFullAccess}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
          >
            {t("inviteBtn")}
          </Button>
        </div>
      </div>
    );

  if (status === StakeholderInvitationStatus.invited)
    return (
      <div>
        <Tag className={classNames("mb-4 mx-auto", classes["invite-tag"])} size="s" variant="information">
          {t("invited")}
        </Tag>

        <div>
          {editButtonPress ? (
            <Button size="s" variant="secondary" onClick={editButtonPress} className="me-2">
              Edit
            </Button>
          ) : null}

          <Button
            size="s"
            className={className}
            onClick={resendInviteHandler}
            isLoading={isResendingInvite}
            variant="secondary"
            isDisabled={!hasFullAccess}
            tooltipTitle={!hasFullAccess ? tCommon("viewOnly") : undefined}
            tooltipMessage={!hasFullAccess ? tCommon("tooltip") : undefined}
          >
            {t("resendInviteBtn")}
          </Button>
        </div>
      </div>
    );
};

export default InviteButton;
