import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, Route, Routes, useNavigate, useParams } from "react-router-dom";
import { Formik, FormikConfig, useFormikContext } from "formik";
import { assoc, clone, dissoc } from "ramda";

import { getEMPath } from "app/Router/RouterHelper";
import { ROUTER_V2 } from "app/Router/RouterV2.types";
import { InstrumentTypesIdsEnum } from "common/enums/enum";
import { ToastFormikValidator } from "common/hooks/useToastFormikValidator";
import { useWizard } from "common/layout/WizardLayout/hooks";
import WizardContent from "common/layout/WizardLayout/WizardContent/WizardContent";
import WizardLayout from "common/layout/WizardLayout/WizardLayout";
import { usePlanValidationSteps } from "common/plan/planValidationSchema";
import usePlanInitialValues from "common/plan/usePlanInitialValues";
import { notify } from "common/utils/notify/notifyFunction";
import { useStoreActions, useStoreState } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import CloseConfirm from "../create-plan/close-confirm/close-confirm";
import grantOneOffPlanContext from "./OneOffPlanContext";
import PlanReceiver from "./steps/PlanReceiver/PlanReceiver";
import PostTerminationExerciseClause from "./steps/PostTerminationExerciseClause/PostTerminationExerciseClause";
import SharesDetails from "./steps/SharesDetails/SharesDetails";
import SharesSource from "./steps/SharesSource/SharesSource";
import Summary from "./steps/Summary/Summary";
import VestingConditions from "./steps/VestingConditions/VestingConditions";
import { PlanForm } from "./types";

export type OwnershipPlanForm = Omit<PlanForm, "id"> & {
  sharesFromStakeholderId?: number;
};

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan");

const GrantOneOffPlanContent: FC = () => {
  const {
    values: { planTypeId },
  } = useFormikContext<OwnershipPlanForm>();
  return (
    <WizardContent maxStep={planTypeId === InstrumentTypesIdsEnum.RSA ? 5 : 6}>
      <ToastFormikValidator />
      <Routes>
        <Route path={ROUTER_V2.equityManagement.createOneOffPlan.planReceiver} element={<PlanReceiver />} />
        <Route path={ROUTER_V2.equityManagement.createOneOffPlan.sharesSource} element={<SharesSource />} />
        <Route path={ROUTER_V2.equityManagement.createOneOffPlan.sharesDetails} element={<SharesDetails />} />
        <Route path={ROUTER_V2.equityManagement.createOneOffPlan.vestingConditions} element={<VestingConditions />} />
        <Route
          path={ROUTER_V2.equityManagement.createOneOffPlan.exerciseClause}
          element={<PostTerminationExerciseClause />}
        />
        <Route path={ROUTER_V2.equityManagement.createOneOffPlan.summary} element={<Summary />} />
        <Route path="*" element={<Navigate to={ROUTER_V2.equityManagement.createOneOffPlan.planReceiver} />} />
      </Routes>
    </WizardContent>
  );
};

const GrantOneOffPlan: FC = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [startingStep] = useState(params["*"]);

  const { wizardStep, wizardTotalSteps } = useWizard();

  const steps = useMemo(
    () =>
      ({
        1: "planReceiver",
        2: "sharesSource",
        3: "sharesDetails",
        4: "vestingConditions",
        5: wizardTotalSteps === 6 ? "exerciseClause" : "summary",
        6: "summary",
      } as const),
    [wizardTotalSteps]
  );

  const validationSchema = usePlanValidationSteps(steps[wizardStep as keyof typeof steps]);

  const companyId = useStoreState((state) => state.activeCompanyModel.companyId);
  const { setOneOffPlanRSAThunk, setOneOffPlanSOThunk } = useStoreActions((actions) => actions.planModel);

  const initialValues: OwnershipPlanForm = usePlanInitialValues(companyId);

  const onSubmit = useCallback<FormikConfig<OwnershipPlanForm>["onSubmit"]>(
    async (values, { setTouched, setSubmitting }) => {
      const step = steps[(wizardStep + 1) as keyof typeof steps];
      setTouched({});

      let submitValues = dissoc("isExcludedClause", clone(values));

      if (steps[wizardStep as keyof typeof steps] === "summary") {
        submitValues.countryId = submitValues?.countryId || null;
        submitValues.file = submitValues?.fileAgreement?.newFile;
        submitValues.sharesFromStakeholderId = submitValues?.sharesFromStakeholderId
          ? submitValues?.sharesFromStakeholderId
          : undefined;

        if (!submitValues.vestingStartsAt) {
          submitValues = dissoc("vestingStartsAt", submitValues);
        }

        const res =
          submitValues.planTypeId === InstrumentTypesIdsEnum.RSA
            ? await setOneOffPlanRSAThunk(submitValues)
            : await setOneOffPlanSOThunk(assoc("excludePostTerminationClauses", values.isExcludedClause, submitValues));

        if (res.status === 200) {
          notify(t("notificationSuccess"), true, "success");
          navigate(getEMPath(["plans", "agreements"]));
        }
      } else {
        navigate(getEMPath(["createOneOffPlan", step]));
      }

      setSubmitting(false);
    },
    [navigate, setOneOffPlanRSAThunk, setOneOffPlanSOThunk, steps, wizardStep]
  );

  useEffect(() => {
    if (startingStep !== "plan-receiver") {
      navigate(getEMPath(["createOneOffPlan", "planReceiver"]));
    }
  }, [navigate, startingStep]);

  return (
    <>
      <WizardLayout.Header title={t("title")} />

      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        <GrantOneOffPlanContent />
      </Formik>
      <CloseConfirm />
    </>
  );
};

const CreateGrantOneOffPlan: FC = () => {
  return (
    <grantOneOffPlanContext.Provider>
      <WizardLayout>
        <GrantOneOffPlan />
      </WizardLayout>
    </grantOneOffPlanContext.Provider>
  );
};

export default CreateGrantOneOffPlan;
