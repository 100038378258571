import { FC, useCallback, useMemo } from "react";
import cn from "classnames";

import { FEATURES } from "common/access-control/types";
import { useFeatures } from "common/access-control/useFeatures";
import { Tooltip } from "common/components/atoms";
import Button from "common/components/atoms/Button/Button";
import ContextMenu, { ContextMenuProps } from "common/components/atoms/ContextMenu/ContextMenu";
import NewAvatar from "common/components/atoms/NewAvatar/NewAvatar";
import Tag from "common/components/atoms/Tag/Tag";
import { Ui } from "common/components/atoms/Typography";
import {
  ActiveUserBudgeIcon,
  DeleteIcon,
  EditIcon,
  EmailIcon,
  MenuTabBarVerticalIcon,
  SendEmailInvitationIcon,
} from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { useStoreActions } from "store/store";
import { createTranslation, TranslationNS } from "translation";

import { Stakeholder } from "../../../types";
import {
  StakeholderContactsCounter,
  StakeholderContactsRowText,
} from "../../stakeholder-contacts-counter/stakeholder-contacts-counter";
import classes from "./stakeholder-card.module.scss";

type PropsTypes = {
  stakeholder: Stakeholder;
  onEdit: (id: number) => void;
  onClick?: (id: number) => void;
  handleRemoveStakeholder?: (stakeholderId: number) => void;
};

const t = createTranslation(TranslationNS.pages, "company.stakeholdersManagement.card");

const StakeholderCard: FC<PropsTypes> = ({
  stakeholder: {
    stakeholderId,
    companyName,
    firstName,
    lastName,
    avatarFilePath,
    email,
    relationship,
    initials,
    isActiveUser,
    hasSentInvite,
    canDelete,
    contacts,
  },
  onEdit,
  onClick,
  handleRemoveStakeholder,
}) => {
  const { openInviteModal, reinviteStakeholderThunk } = useStoreActions((actions) => actions.stakeholderInvitation);
  const { hasFullAccess } = useFeatures(FEATURES.stakeholdersManagement);

  const handleEditClick = useCallback(() => {
    onEdit(stakeholderId || 0);
  }, [onEdit, stakeholderId]);

  const handleInviteClick = useCallback(() => {
    openInviteModal({
      stakeholderId,
      firstName,
      lastName,
      email,
      relationshipId: Number(relationship),
    });
  }, [email, firstName, lastName, openInviteModal, relationship, stakeholderId]);

  const handleDeleteClick = useCallback(() => {
    handleRemoveStakeholder?.(stakeholderId);
  }, [handleRemoveStakeholder, stakeholderId]);

  const handleClickCard = useCallback(() => {
    onClick?.(stakeholderId);
  }, [onClick, stakeholderId]);

  const handleReinviteClick = useCallback(() => {
    reinviteStakeholderThunk(stakeholderId);
    document.body.click();
  }, [reinviteStakeholderThunk, stakeholderId]);

  const dropdownItems = useMemo(() => {
    const commonItems: ContextMenuProps["items"][0][] = [
      {
        key: "edit",
        label: t("editBtn"),
        icon: <EditIcon />,
        onClick: handleEditClick,
        isDisabled: !hasFullAccess,
      },
    ];

    if (!isActiveUser && !hasSentInvite) {
      commonItems.push({
        key: "invite",
        label: t("inviteBtn"),
        icon: <SendEmailInvitationIcon />,
        onClick: handleInviteClick,
        isDisabled: !hasFullAccess,
      });
    }

    if (!isActiveUser && hasSentInvite) {
      commonItems.push({
        key: "reinvite",
        label: t("reinviteBtn"),
        icon: <SendEmailInvitationIcon />,
        onClick: handleReinviteClick,
        isDisabled: !hasFullAccess,
      });
    }

    if (canDelete) {
      commonItems.push({
        key: "delete",
        label: "Delete",
        type: "delete",
        onClick: handleDeleteClick,
        icon: <DeleteIcon />,
        isDisabled: !hasFullAccess,
      });
    }

    return commonItems;
  }, [
    handleEditClick,
    hasFullAccess,
    isActiveUser,
    hasSentInvite,
    canDelete,
    handleInviteClick,
    handleReinviteClick,
    handleDeleteClick,
  ]);

  return (
    <div className={classes.container} data-testid="user-card" onClick={handleClickCard}>
      <div className={cn("d-flex justify-content-between mt-3 mb-3 px-3")}>
        <div>
          <div className="d-flex">
            {companyName ? (
              <div>
                <div className="d-flex align-items-center">
                  <Ui.l className="mb-1" bold>
                    {companyName}
                  </Ui.l>

                  {isActiveUser ? (
                    <Tooltip popupContent={<div>Registered user</div>}>
                      <ActiveUserBudgeIcon fontSize={16} className="ms-1 mb-1" />
                    </Tooltip>
                  ) : null}

                  {!isActiveUser && hasSentInvite && (
                    <Tooltip popupContent={<div>{t("invited")}</div>}>
                      <EmailIcon fontSize={16} className="ms-1 mb-1" color={scssVariables.foregroundLow} />
                    </Tooltip>
                  )}
                </div>

                <div className="d-flex align-items-center flex-wrap">
                  <StakeholderContactsRowText
                    contacts={
                      contacts.length === 0
                        ? [
                            {
                              name: `${firstName} ${lastName}`,
                              isPrimary: true,
                            },
                          ]
                        : (contacts as unknown as {
                            name: string;
                            isPrimary: boolean;
                          }[])
                    }
                  />

                  <StakeholderContactsCounter
                    contacts={
                      contacts as unknown as {
                        name: string;
                        isPrimary: boolean;
                      }[]
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex align-items-center">
                <Ui.l bold className="mb-1">{`${firstName} ${lastName}`}</Ui.l>

                {isActiveUser ? (
                  <Tooltip popupContent={<div>Registered user</div>}>
                    <ActiveUserBudgeIcon fontSize={16} className="ms-1 mb-1" />
                  </Tooltip>
                ) : null}

                {!isActiveUser && hasSentInvite && (
                  <Tooltip popupContent={<div>{t("invited")}</div>}>
                    <EmailIcon fontSize={16} className="ms-1 mb-1" color={scssVariables.foregroundLow} />
                  </Tooltip>
                )}
              </div>
            )}
          </div>
          <Ui.xs className={cn("mb-3", classes.email)}>{email}</Ui.xs>
        </div>
        <NewAvatar imageUrl={avatarFilePath} initials={initials} company={!!companyName} />
      </div>
      <div className={cn("d-flex justify-content-between align-items-center pt-1 px-3 mb-1 mt-auto", classes.divider)}>
        <Tag variant="closed">{relationship}</Tag>

        <ContextMenu items={dropdownItems} withPropagation>
          <Button isOnlyIcon variant="tertiary" data-testid="plan-dropdown-btn">
            <MenuTabBarVerticalIcon />
          </Button>
        </ContextMenu>
      </div>
    </div>
  );
};

export default StakeholderCard;
