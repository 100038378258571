import { FC } from "react";

import Spinner from "common/components/atoms/Spinner/Spinner";

import OverviewComponent from "./OverviewComponent.tsx/OverviewComponent";
import PlansPrograms from "./PlansPrograms/PlansPrograms";

type CompanyManagePlansBodyProps = {
  isLoading: boolean;
};

const CompanyManagePlansBody: FC<CompanyManagePlansBodyProps> = ({ isLoading }) => {
  return (
    <div>
      <OverviewComponent />

      <div className="mt-4" />
      <PlansPrograms />

      {isLoading && <Spinner />}
    </div>
  );
};

export default CompanyManagePlansBody;
