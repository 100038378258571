import { FC, useCallback, useMemo } from "react";
import { Col } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { Form, Formik, FormikHelpers } from "formik";
import { defaultTo, isEmpty, isNil } from "ramda";
import * as Yup from "yup";

import { Button, DatePicker, SlidePanel, TextArea, TextField } from "common/components/atoms";
import { dateFormatWithTime } from "common/components/atoms/DatePicker/DatePicker";
import { FilesDataMultiple } from "common/components/atoms/FileUploader/FileUploader";
import DocumentUploadWithStatusesSignatureEmailInvitation from "common/components/molecules/DocumentUploadWithStatusesSignatureEmailInvitation/DocumentUploadWithStatusesSignatureEmailInvitation";
import useCurrency from "common/hooks/useCurrency";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { transformDateToCommonDateFormat } from "common/utils/functions";
import { createTranslation, TranslationNS } from "translation";

import { ChangeNominalValuePostDTO, NominalValueGetResponseDTO } from "../../../types";
import { documentationDetailsValidationSchema } from "../form-sections/documentation-details/validation";
import { ChangeNominalValueFields } from "./form-fields";

const [t, tValidation] = [
  createTranslation(TranslationNS.pages, "company.transactions"),
  createTranslation(TranslationNS.validation),
];

type Props = {
  onSubmit: (transaction: FormData, resetForm?: boolean) => Promise<boolean>;
  onClose: () => void;
  dataToEdit?: NominalValueGetResponseDTO | null;
  initialNominalValue?: number;
  minTransactionDate?: string;
};

const ChangeNominalValueForm: FC<Props> = ({
  minTransactionDate,
  onSubmit,
  initialNominalValue,
  dataToEdit,
  onClose,
}) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const { formattedCurrency } = useCurrency();
  const fNumber = useFormatNumbers();

  const defaultTransactionDate = useMemo(() => {
    const todayMidday = format(new Date().setHours(12, 0, 0, 0), dateFormatWithTime);

    if (dataToEdit?.transactionDate) {
      return dataToEdit?.transactionDate;
    }

    if (minTransactionDate) {
      if (todayMidday > minTransactionDate) {
        return todayMidday;
      } else {
        return minTransactionDate;
      }
    }

    return todayMidday;
  }, [dataToEdit?.transactionDate, minTransactionDate]);

  const formInitialValues: ChangeNominalValuePostDTO = useMemo(() => {
    return {
      [ChangeNominalValueFields.companyId]: Number(companyId),
      [ChangeNominalValueFields.nominalShareValue]: defaultTo(undefined, dataToEdit?.nominalShareValue),
      [ChangeNominalValueFields.documentStatusId]: defaultTo(
        undefined as unknown as number,
        dataToEdit?.documentStatusId
      ),
      [ChangeNominalValueFields.description]: defaultTo("", dataToEdit?.description),
      [ChangeNominalValueFields.transactionDate]: defaultTransactionDate,
      [ChangeNominalValueFields.documents]: [],
      [ChangeNominalValueFields.prevFiles]: defaultTo([], dataToEdit?.documentFiles),
    };
  }, [companyId, dataToEdit, defaultTransactionDate]);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        ...documentationDetailsValidationSchema(tValidation),
        transactionDate: Yup.string().required(tValidation("required")),
        nominalShareValue: Yup.number()
          .required(tValidation("required"))
          .min(0.000001, tValidation("minNumber", { number: "0,000001" })),
      }),
    []
  );

  const handleSubmit = useCallback(
    async (values: any, actions: FormikHelpers<ChangeNominalValuePostDTO>) => {
      const formData = new FormData();

      if (!isNil(dataToEdit)) {
        formData.append("TransactionId", String(dataToEdit.transactionId));
      }

      Object.values(ChangeNominalValueFields).forEach((key) => {
        const formValue = values[key];

        if (!formValue) return;

        if (key === ChangeNominalValueFields.documents) {
          if (!isEmpty(formValue)) {
            values.documents.forEach((el: any) => formData.append("FilesData.Files", el));
          }
        } else {
          formData.append(key, String(formValue).trim());
        }
      });

      if (!isEmpty(values["prevFiles"])) {
        values.prevFiles.forEach((el: any) => formData.append("FilesData.OldFileIds", el.fileId));
      }

      const createdSuccessfully = await onSubmit(formData);

      if (!createdSuccessfully) {
        actions.setSubmitting(false);
      }
    },
    [dataToEdit, onSubmit]
  );

  return (
    <Formik
      onSubmit={handleSubmit}
      enableReinitialize
      initialValues={formInitialValues}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, submitForm, handleChange, setFieldValue, errors, values, touched }) => {
        const handleDocumentChange = (data: FilesDataMultiple) => {
          setFieldValue(ChangeNominalValueFields.documents, data.newFiles);
          setFieldValue("prevFiles", defaultTo([], data.oldFiles));
        };

        const handleDocumentsStatusChange = (statusId: number) => {
          setFieldValue(ChangeNominalValueFields.documentStatusId, statusId);
        };

        return (
          <Form>
            <SlidePanel.Header title={t("changeNominalValueForm.title")} onHide={onClose} isEdit={!isNil(dataToEdit)} />

            <SlidePanel.Section title={t("transactionDetails.title")}>
              <Col xs={6}>
                <DatePicker
                  name={ChangeNominalValueFields.transactionDate}
                  label={t("changeNominalValueForm.date")}
                  date={values.transactionDate}
                  error={errors.transactionDate}
                  isTouched={touched.transactionDate}
                  minDate={minTransactionDate ? new Date(minTransactionDate) : undefined}
                  info={t("transactionDetails.minDateInfo", {
                    minDate: transformDateToCommonDateFormat(minTransactionDate),
                  })}
                  isDateOnlyString
                  isWithTimeSelect
                  onChange={(date) => {
                    setFieldValue(ChangeNominalValueFields.transactionDate, date);
                  }}
                />
              </Col>

              <TextArea
                isOptional
                className="mt-4"
                value={values.description}
                label={t("changeNominalValueForm.description")}
                placeholder={t("changeNominalValueForm.descrPlaceholder")}
                onChange={handleChange("description")}
              />
            </SlidePanel.Section>

            <SlidePanel.Section title={t("changeNominalValueForm.nominalValue")}>
              <Col xs={6}>
                <TextField
                  min={0}
                  type="number"
                  value={values.nominalShareValue}
                  error={errors.nominalShareValue}
                  isTouched={touched.nominalShareValue}
                  onChange={handleChange("nominalShareValue")}
                  label={t("changeNominalValueForm.newNominalValue")}
                  iconRight={formattedCurrency}
                  placeholder={fNumber(initialNominalValue, "value", { hideCurrencySymbol: true })}
                  info={t("changeNominalValueForm.newNominalValueInfo", {
                    value: fNumber(initialNominalValue, "value"),
                  })}
                />
              </Col>
            </SlidePanel.Section>

            <SlidePanel.Section title={t("documentationDetails.title")}>
              <DocumentUploadWithStatusesSignatureEmailInvitation
                error={errors.documentStatusId as unknown as string}
                touched={Boolean(touched.documentStatusId)}
                documentStatus={values.documentStatusId || 0}
                onFileUploaderChange={handleDocumentChange}
                setDocumentStatus={handleDocumentsStatusChange}
                isInviteFlowDisabled={true}
                prevData={dataToEdit?.documentFiles as any}
              />
            </SlidePanel.Section>

            <SlidePanel.Actions>
              <Button isLoading={isSubmitting} onClick={submitForm} type="submit" className="me-3">
                {t("sharedFormButtons.submitBtn")}
              </Button>

              <Button isDisabled={isSubmitting} onClick={onClose} variant="secondary">
                {t("sharedFormButtons.cancelBtn")}
              </Button>
            </SlidePanel.Actions>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangeNominalValueForm;
