import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ColumnDef } from "@tanstack/react-table";

import {
  Actions,
  CheckboxControllerInput,
  CheckboxInput,
  DateInput,
  SelectInput,
  TextInput,
} from "common/components/atoms/ImportTable";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers from "common/hooks/useFormatNumbers";
import { OnboardingTransactionGetDTO, ShareClassGetDTO } from "store/modelTypes";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ImportTransaction.module.scss";
import SourceField from "./SourceField/SourceField";
import TransactionField from "./TransactionField/TransactionField";
import { fields, ImportTransactionFormValues } from "./useImportTransactionForm";

const t = createTranslation(TranslationNS.pages, "onboard.company.importTransaction");

const useImportTransactionColumns = (
  shareClasses?: Pick<ShareClassGetDTO, "name" | "id">[],
  transactionTypes?: OnboardingTransactionGetDTO["transactionTypes"],
  stakeholders?: OnboardingTransactionGetDTO["stakeholders"],
  currencyId?: number
) => {
  const { i18n } = useTranslation();
  const { currencySymbol } = useCurrencyById(currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const hasFromShareholder = useMemo(() => {
    if (transactionTypes) {
      return transactionTypes?.filter((type) => type?.hasFromShareholder).map((stakeholder) => stakeholder?.id);
    }
    return [];
  }, [transactionTypes]);

  const transactionTypeWithEmpty = useMemo(() => {
    return transactionTypes ? transactionTypes : [];
  }, [transactionTypes]);

  const shareClassesWithEmpty = useMemo(() => {
    return shareClasses ? [...shareClasses.map((item) => ({ id: item.name, name: item.name }))] : [];
  }, [shareClasses]);

  const stakeholdersWithEmpty = useMemo(() => {
    return stakeholders ? stakeholders : [];
  }, [stakeholders]);

  const sourceWithEmpty = useMemo(() => {
    return [{ id: 1, name: "New shares" }];
  }, []);

  const [countOfActiveCheckboxes, setCountOfActiveCheckboxes] = useState(0);

  const columns = useMemo<ColumnDef<ImportTransactionFormValues>[]>(() => {
    const cells: ColumnDef<ImportTransactionFormValues>[] = [
      {
        header: (props) => {
          return <CheckboxControllerInput columnId={props.column.id} allActionsCallback={setCountOfActiveCheckboxes} />;
        },
        accessorKey: "checkbox",
        cell: (props) => <CheckboxInput rowIndex={props.row.index} columnId={props.column.id} />,
        maxSize: 38,
      },
      {
        header: t("fields.transactionNumber"),
        accessorKey: "transactionNumber",
        cell: (props) => <div className={classes.transactionNumber}>{props.row.index + 1}</div>,
        size: 100,
        footer: () => {
          return <span>{t("total")}</span>;
        },
      },
      {
        header: t("fields.transactionDate") + " *",
        accessorKey: fields.transactionDate,
        cell: (props) => (
          <DateInput rowIndex={props.row.index} columnId={props.column.id} placeholder="dd.mm.yyyy" isWithTimeSelect />
        ),
        minSize: 170,
      },
      {
        header: t("fields.transactionTypeId"),
        accessorKey: fields.transactionType,
        cell: (props) => (
          <TransactionField
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={transactionTypeWithEmpty}
            isSearchable={false}
            resetValue={fields.sourceShareholderId}
            placeholder={t("notSelected")}
            isClearable
          />
        ),
        minSize: 210,
      },
      {
        header: t("fields.numberOfShares") + " *",
        accessorKey: fields.numberOfShares,
        cell: (props) => (
          <TextInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            className={classes.shareClassAlign}
            type="number"
            placeholder={t("fields.shares")}
          />
        ),
        minSize: 170,
        footer: ({ table }) => {
          const total = table
            .getFilteredRowModel()
            .rows.reduce((sum, row) => +sum + +(row.original.numberOfShares || 0), 0);

          return (
            <div className={"text-end"}>
              <span>{fNumber(total, "amount")}</span>
            </div>
          );
        },
        size: 80,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("fields.sharePrice") + " *",
        accessorKey: fields.sharePrice,
        cell: (props) => (
          <TextInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            className={classes.shareClassAlign}
            type="number"
            iconRight={currencySymbol}
          />
        ),
        minSize: 160,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("fields.sum"),
        accessorKey: fields.total,
        cell: (props) => {
          const numberOfShares = (props.row.getValue(fields.numberOfShares) as number) || 0;
          const price = (props.row.getValue(fields.sharePrice) as number) || 0;

          return <div className={classes.transactionNumber}>{fNumber(numberOfShares * price, "value")}</div>;
        },
        size: 140,
        meta: {
          headClass: "text-end",
        },
      },
      {
        header: t("fields.shareClassId") + " *",
        accessorKey: fields.shareClassName,
        cell: (props) => (
          <SelectInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={shareClassesWithEmpty}
            isSearchable
            placeholder={t("notSelected")}
          />
        ),
        minSize: 170,
      },
      {
        header: t("fields.receivingShareholderId") + " *",
        accessorKey: fields.receivingShareholderId,
        cell: (props) => (
          <SelectInput
            rowIndex={props.row.index}
            columnId={props.column.id}
            options={stakeholdersWithEmpty}
            isSearchable
            placeholder={t("notSelected")}
          />
        ),
        minSize: 170,
      },
      {
        header: t("fields.sourceId") + " *",
        accessorKey: fields.sourceShareholderId,
        cell: (props) => (
          <SourceField
            rowIndex={props.row.index}
            columnId={props.column.id}
            sourceOptions={sourceWithEmpty}
            stakeholderOptions={stakeholdersWithEmpty}
            hasFromShareholder={hasFromShareholder}
            dependOnField={fields.transactionType}
            placeholder={t("notSelected")}
          />
        ),
        minSize: 166,
      },
      {
        accessorKey: "actions",
        header: "",
        cell: ({ row, table }) => (
          <Actions
            rowIndex={row.index}
            updateData={table.options.meta?.updateData}
            globalFilter={table.getState().globalFilter}
          />
        ),
        maxSize: 42,
      },
    ];

    return cells;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    i18n.language,
    currencySymbol,
    hasFromShareholder,
    shareClassesWithEmpty,
    sourceWithEmpty,
    stakeholdersWithEmpty,
    transactionTypeWithEmpty,
    fNumber,
  ]);

  return { columns, countOfActiveCheckboxes };
};

export default useImportTransactionColumns;
