import { useCallback } from "react";
import axios from "axios";

import {
  AvailableShareClass,
  AvailableShareClassesPostDTO,
  AvailableShareClassesPostResponseDTO,
  BuySellCheckDTO,
  BuySellGetResponseDTO,
  BuySellPostResponseDTO,
  CapitalIncreaseFormGetDTO,
  CapitalIncreaseGetDTO,
  CapitalIncreaseTransactionsPostDTO,
  CheckResponseDTO,
  ConfirmCapitalIncreaseTransactionPostDTO,
  ConfirmIssueSharesTransactionPostDTO,
  ConfirmNominalValuePostDTO,
  ConfirmSplitTransactionPostDTO,
  GetTransactionsDTO,
  IssueSharesCheckDTO,
  IssueSharesEditDTO,
  IssueSharesGetResponseDTO,
  IssueSharesPostResponseDTO,
  NominalValueGetResponseDTO,
  SplitGetResponseDTO,
  SplitPostResponseDTO,
  SplitPreviewGetResponseDTO,
  UserTransactionsGetDTO,
} from "./types";

export const transactionsApiBase = "/api/ownership/transaction";

export const useTransactionsService = (companyId: string) => {
  const getTransactions = useCallback(async () => {
    try {
      const response = await axios.get<GetTransactionsDTO>(`${transactionsApiBase}/${companyId}`);

      // Temp check for transition from staging to main
      if (response.status === 200) {
        if (Array.isArray(response.data)) {
          return {
            nominalValue: 0,
            companyHasShares: true,
            transactions: response.data,
            transactedAtMax: "2023-12-13T17:32:44.579",
          };
        } else {
          return response.data;
        }
      }

      return {
        companyHasShares: false,
        transactions: [],
        transactedAtMax: "",
        nominalValue: response.data.nominalValue,
      };
    } catch (e) {
      console.log(e);
      return {
        companyHasShares: false,
        transactions: [],
        transactedAtMax: "",
        nominalValue: 0,
      };
    }
  }, [companyId]);

  // this request returns only transactions where user participated in (used in portfolio)
  const getUserTransactions = useCallback(async () => {
    try {
      const response = await axios.get<UserTransactionsGetDTO>(`${transactionsApiBase}/user/${companyId}`);

      if (response.status === 200) {
        return response.data.transactions;
      }

      return [];
    } catch (e) {
      console.log(e);
    }
  }, [companyId]);

  const getIssueSharesTransaction = useCallback(async (transactionId: number) => {
    try {
      const response = await axios.get<IssueSharesGetResponseDTO>(
        `${transactionsApiBase}/pending-issue/${transactionId}`
      );
      if (response.status === 200) return response.data;
      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }, []);

  const postIssueSharesTransaction = useCallback(async (transaction: FormData) => {
    try {
      const response = await axios.post<IssueSharesPostResponseDTO>(
        `${transactionsApiBase}/pending-issue/add`,
        transaction
      );

      if (response.status === 200) return response.data.transactionId;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const editPendingIssueSharesTransaction = useCallback(async (transaction: FormData) => {
    try {
      const response = await axios.post(`${transactionsApiBase}/pending-issue/edit`, transaction);

      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const editConfirmedIssueSharesTransaction = useCallback(async (transaction: FormData) => {
    try {
      const response = await axios.post(`${transactionsApiBase}/issue/edit`, transaction);

      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const checkIssueSharesTransaction = useCallback(async (transaction: IssueSharesCheckDTO) => {
    try {
      const response = await axios.post<CheckResponseDTO>(`${transactionsApiBase}/issue/check`, transaction);

      if (response.status === 200) return response.data;
      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } catch (e) {
      console.log(e);
      return { failedTransactions: [], hasErrorsAfterEdit: true };
    }
  }, []);

  const postConfirmIssueSharesTransaction = useCallback(
    async (confirmTransactionData: ConfirmIssueSharesTransactionPostDTO) => {
      try {
        const response = await axios.post(`${transactionsApiBase}/pending-issue/confirm`, confirmTransactionData);

        if (response.status === 200) return true;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
    []
  );

  const getBuySellTransaction = useCallback(async (transactionId: number) => {
    try {
      const response = await axios.get<BuySellGetResponseDTO>(`${transactionsApiBase}/buy-sell/${transactionId}`);

      if (response.status === 200) return response.data;
      return null;
    } catch (e) {
      console.log(e);
      return null;
    }
  }, []);

  const postBuySellTransaction = useCallback(async (transaction: FormData) => {
    try {
      const response = await axios.post<BuySellPostResponseDTO>(`${transactionsApiBase}/buy-sell/add`, transaction);

      // TODO will be fixes later
      // if (response.status === 200) return response.data.transactionId;
      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const checkBuySellTransaction = useCallback(async (transaction: BuySellCheckDTO) => {
    try {
      const response = await axios.post<CheckResponseDTO>(`${transactionsApiBase}/buy-sell/check`, transaction);

      if (response.status === 200) return response.data;
      return { failedTransactions: [], hasErrorsAfterEdit: true };
    } catch (e) {
      console.log(e);
      return { failedTransactions: [], hasErrorsAfterEdit: true };
    }
  }, []);

  const editBuySellTransaction = useCallback(async (transaction: FormData) => {
    try {
      const response = await axios.post<BuySellPostResponseDTO>(`${transactionsApiBase}/buy-sell/edit`, transaction);

      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const deleteTransaction = useCallback(async (transactionId: number) => {
    try {
      const response = await axios.delete(`${transactionsApiBase}/${transactionId}`);

      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const rollbackTransaction = useCallback(
    async (transactionId: number) => {
      try {
        const response = await axios.post<CheckResponseDTO>(`${transactionsApiBase}/rollback/check`, {
          transactionId,
          companyId: Number(companyId),
        });

        if (response.status === 200) return response.data;
        return { failedTransactions: [], hasErrorsAfterEdit: true };
      } catch (e) {
        console.log(e);
        return { failedTransactions: [], hasErrorsAfterEdit: true };
      }
    },
    [companyId]
  );

  const getAvailableShareClasses = useCallback(async (data: AvailableShareClassesPostDTO) => {
    try {
      const response = await axios.post<AvailableShareClassesPostResponseDTO>(
        `${transactionsApiBase}/available-shares`,
        data
      );
      if (response.status === 200) return response.data.shareClasses;
      return [] as AvailableShareClass[];
    } catch (e) {
      console.log(e);
      return [] as AvailableShareClass[];
    }
  }, []);

  const getSplitPreview = useCallback(
    async (multiplier: number) => {
      try {
        const response = await axios.get<SplitPreviewGetResponseDTO>(
          `${transactionsApiBase}/split/snapshot-preview/${companyId}/${multiplier}`
        );

        if (response.status === 200) return response.data;
      } catch (e) {
        console.log(e);
      }
    },
    [companyId]
  );

  const getSplitSnapshot = useCallback(async (transactionId: number) => {
    try {
      const response = await axios.get<SplitPreviewGetResponseDTO>(
        `${transactionsApiBase}/split/snapshot/${transactionId}`
      );

      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getSplitTransaction = useCallback(async (transactionId: number) => {
    try {
      const response = await axios.get<SplitGetResponseDTO>(`${transactionsApiBase}/split/${transactionId}`);

      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const postSplitTransaction = useCallback(async (transaction: FormData) => {
    try {
      const response = await axios.post<SplitPostResponseDTO>(`${transactionsApiBase}/split/add`, transaction);

      if (response.status === 200) return response.data.transactionId;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const postConfirmSplitTransaction = useCallback(async (confirmTransactionData: ConfirmSplitTransactionPostDTO) => {
    try {
      const response = await axios.post(`${transactionsApiBase}/split/confirm`, confirmTransactionData);

      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, []);

  const getCapitalIncrease = useCallback(async (capitalIncreaseId: number) => {
    try {
      const response = await axios.get<CapitalIncreaseGetDTO>(
        `${transactionsApiBase}/capital-increase/details/${capitalIncreaseId}`
      );

      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const getCapitalIncreaseWizardData = useCallback(async (capitalIncreaseId: number) => {
    try {
      const response = await axios.get<CapitalIncreaseFormGetDTO>(
        `${transactionsApiBase}/capital-increase/${capitalIncreaseId}`
      );
      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const postCapitalIncreaseGeneralData = useCallback(async (data: FormData) => {
    try {
      const response = await axios.post<CapitalIncreaseFormGetDTO>(`${transactionsApiBase}/capital-increase`, data);

      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const postCapitalIncreaseTransactionsData = useCallback(async (data: CapitalIncreaseTransactionsPostDTO) => {
    try {
      const response = await axios.post(`${transactionsApiBase}/capital-increase/transactions`, data);
      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, []);

  const deleteCapitalIncreaseTransaction = useCallback(async (capitalIncreaseId: number) => {
    try {
      const response = await axios.delete(`${transactionsApiBase}/capital-increase/${capitalIncreaseId}`);

      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const postConfirmCapitalIncreaseTransaction = useCallback(async (data: ConfirmCapitalIncreaseTransactionPostDTO) => {
    try {
      const response = await axios.post(`${transactionsApiBase}/capital-increase/confirm`, data);

      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
    }
  }, []);

  const postConfirmNominalValueTransaction = useCallback(async (confirmTransactionData: ConfirmNominalValuePostDTO) => {
    try {
      const response = await axios.post(`${transactionsApiBase}/nominal-value/confirm`, confirmTransactionData);

      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, []);

  const postChangeNominalValue = useCallback(async (changeValueData: FormData) => {
    try {
      const response = await axios.post(`${transactionsApiBase}/nominal-value/add`, changeValueData);

      if (response.status === 200) return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, []);

  const getSingleNominalValueDetails = useCallback(async (transactionId: number) => {
    try {
      const response = await axios.get<NominalValueGetResponseDTO>(
        `${transactionsApiBase}/nominal-value/${transactionId}`
      );

      if (response.status === 200) return response.data;
    } catch (e) {
      console.log(e);
      return false;
    }
  }, []);

  return {
    getTransactions,
    getUserTransactions,

    getIssueSharesTransaction,
    postIssueSharesTransaction,
    checkIssueSharesTransaction,
    editPendingIssueSharesTransaction,
    editConfirmedIssueSharesTransaction,
    postConfirmIssueSharesTransaction,

    getBuySellTransaction,
    postBuySellTransaction,
    checkBuySellTransaction,
    editBuySellTransaction,

    getSplitPreview,
    getSplitSnapshot,
    getSplitTransaction,
    postSplitTransaction,
    editSplitTransaction: postSplitTransaction,
    postConfirmSplitTransaction,

    deleteTransaction,
    rollbackTransaction,

    getAvailableShareClasses,

    getCapitalIncrease,
    getCapitalIncreaseWizardData,
    postCapitalIncreaseGeneralData,
    postCapitalIncreaseTransactionsData,
    deleteCapitalIncreaseTransaction,
    postConfirmCapitalIncreaseTransaction,

    postChangeNominalValue,
    getSingleNominalValueDetails,
    postConfirmNominalValueTransaction,
  };
};
