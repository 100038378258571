import React, { FC, useMemo } from "react";
import { PointTooltipProps } from "@nivo/line";
import cn from "classnames";
import { format } from "date-fns";
import * as R from "ramda";

import { P, StepChart, Ui } from "common/components/atoms";
import { setMissedQuarters, transformChartAxisToYearsOrQuarters } from "common/helpers";
import useCurrencyById from "common/hooks/useCurrencyById";
import useFormatNumbers, { formatNumber, FormatNumbers } from "common/hooks/useFormatNumbers";
import EmptyChart from "common/icons/empty_chart.png";
import EmptyContainer from "common/icons/empty_container.png";
import { FilledArrowDownIcon } from "common/icons/svg";
import { scssVariables } from "common/utils/constants";
import { CommonOwnershipPlanDetails } from "store/types";
import { createTranslation, TranslationNS } from "translation";

import classes from "./ReleaseScheduleChart.module.scss";

const t = createTranslation(TranslationNS.common, "organisms.agreement.releaseScheduleChart");

const RenderTooltip: FC<PointTooltipProps & { formatNumber: FormatNumbers }> = ({ point, formatNumber }) => {
  return (
    <div className={classes.tooltip}>
      <Ui.xs bold className="mt-half">
        {formatNumber(point.data.y as number, "amount")} shares
      </Ui.xs>
      <Ui.xs color="foregroundLow">{format(new Date(point.data.x), "MMM dd, yyyy")}</Ui.xs>
      <div className={classes.triangle}>
        <FilledArrowDownIcon width={25} height={10} color="white" direction="bottom" />
      </div>
    </div>
  );
};

const ScheduleChartContent: FC<{ agreement: CommonOwnershipPlanDetails | null; isLosted?: boolean }> = ({
  agreement,
  isLosted,
}) => {
  const { currencySymbol } = useCurrencyById(agreement?.currencyId);
  const fNumber = useFormatNumbers(currencySymbol);

  const data = useMemo(() => {
    if (
      !R.isNil(agreement) &&
      (!R.isEmpty(agreement?.vestedDataPoints) || !R.isEmpty(agreement?.restrictedDataPoints))
    ) {
      const restrictedData =
        !R.isNil(agreement.restrictedDataPoints) && !R.isEmpty(agreement.restrictedDataPoints)
          ? agreement.restrictedDataPoints
          : [];

      return [
        {
          id: "Shares vested",
          color: scssVariables.positive500,
          data: setMissedQuarters(agreement?.vestedDataPoints),
        },
        {
          id: "Total",
          color: isLosted ? scssVariables.critical700 : scssVariables.primary1,
          data: setMissedQuarters([
            {
              x: agreement?.restrictedDataPoints?.[0]?.x || 0,
              y: agreement?.vestedDataPoints?.[agreement.vestedDataPoints?.length - 1]?.y || 0,
            },
            ...restrictedData,
          ]),
        },
      ];
    }
  }, [agreement, isLosted]);

  const bottomValues = useMemo(
    () =>
      data &&
      transformChartAxisToYearsOrQuarters({
        data: R.concat(data?.[0]?.data, data?.[1]?.data) || [],
      }),
    [data]
  );

  const marginLeft =
    formatNumber(Math.max(...(R.pluck("y", agreement?.restrictedDataPoints || []) as number[]))).length * 8 + 10;

  return (
    <div>
      {R.isNil(data) || !data.length ? (
        <>
          <div className={classes["empty-image-container"]}>
            <img alt="" src={EmptyContainer} />
            <img alt="" src={EmptyChart} />
          </div>
          <P.m className="mt-5">{t("empty")}</P.m>
        </>
      ) : (
        <StepChart
          height={200}
          disableLegend
          gridYValues={4}
          gridXValues={5}
          showPointOnHover
          tooltip={(props) => <RenderTooltip {...props} formatNumber={fNumber} />}
          data={R.defaultTo([], data)}
          axisRight={{
            tickSize: 2,
            tickValues: [0, fNumber(agreement?.totalSharesValue || 0)],
          }}
          margin={{
            left: marginLeft,
            right: 50,
            top: 20,
            bottom: 30,
          }}
          axisLeft={{
            tickSize: 0,
            tickValues: 4,
            tickPadding: 10,
            format: (value) => fNumber(value, "amount", { hideCurrencySymbol: true }),
          }}
          axisBottom={{
            tickSize: 0,
            renderTick: (tick) => {
              const isTickVisible = bottomValues?.includes(tick.value);

              return isTickVisible ? (
                <foreignObject width={80} height={15} x={tick.x - 10} y={tick.y + 16}>
                  <p className="m-0 ui-xs" style={{ color: scssVariables.foregroundMedium }}>
                    {format(new Date(tick.value), "MMM dd, yyyy")}
                  </p>
                </foreignObject>
              ) : (
                <></>
              );
            },
          }}
        />
      )}

      {!R.isNil(data) && data.length ? (
        <div
          className={cn("ms-2 mt-4", classes["legend"], {
            [classes["is-losted"]]: isLosted,
          })}
        >
          <div>
            <span className="me-1" />
            <p className="m-0 ui-xs">{t("vested")}</p>
          </div>

          <div className="ms-4">
            <span className="me-1" />
            <p className="m-0 ui-xs">{t("waitingToVest")}</p>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ScheduleChartContent;
