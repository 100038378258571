import { FC, ReactNode, useCallback } from "react";
import { Dropdown } from "react-bootstrap";
import { useFormikContext } from "formik";

import { MenuTabBarVerticalIcon } from "common/icons/svg";
import { createTranslation, TranslationNS } from "translation";

import { scssVariables } from "../../../../utils/constants";
import Tooltip from "../../Tooltip/Tooltip";
import classes from "./inputs.module.scss";

const t = createTranslation(TranslationNS.common, "importTable.actions");

type ActionsProps = {
  rowIndex: number;
  updateData?: (rowIndex: number, type: string) => void;
  globalFilter?: string;
  canDoActionField?: string;
  popupContent?: ReactNode;
};

type FormikValues = {
  [key: string]: string | number | undefined | boolean;
};
const Actions: FC<ActionsProps> = ({ rowIndex, updateData, globalFilter, canDoActionField, popupContent }) => {
  const { values } = useFormikContext<FormikValues[]>();

  const handleDelete = useCallback(() => {
    updateData?.(rowIndex, "deleteRow");
  }, [rowIndex, updateData]);

  const handleCopy = useCallback(() => {
    updateData?.(rowIndex, "copyRow");
  }, [rowIndex, updateData]);

  const handleAdd = useCallback(() => {
    updateData?.(rowIndex, "addRow");
  }, [rowIndex, updateData]);

  const disabled = canDoActionField ? values[rowIndex]?.[canDoActionField] === false : false;

  if (globalFilter) {
    return null;
  }

  return (
    <div className={classes.actions}>
      <Dropdown className={classes.dropdown} id={`actions_id_${rowIndex}`}>
        <Dropdown.Toggle disabled={!!globalFilter} className={classes.iconButton}>
          <MenuTabBarVerticalIcon color={scssVariables.foregroundHigh} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item as="span" onClick={handleCopy}>
            {t("copyRow")}
          </Dropdown.Item>
          <Dropdown.Item as="span" onClick={handleAdd}>
            {t("addNewRow")}
          </Dropdown.Item>
          <Tooltip placement="left" show={disabled ? undefined : false} popupContent={popupContent} rootCloseDisabled>
            <div>
              <Dropdown.Item disabled={disabled} as="span" onClick={handleDelete}>
                {t("deleteRow")}
              </Dropdown.Item>
            </div>
          </Tooltip>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Actions;
