import { FC, useState } from "react";
import { useParams } from "react-router-dom";

import { useTransactionsService } from "../../transactions-service";
import { Transaction } from "../../types";
import Confirm from "./confirm";
import DependentTransactions from "./dependent-transactions";

type PropsTypes = {
  transactionToRollback: Transaction;
  onSubmit: (transactionId: number, isRollback?: boolean) => Promise<boolean>;
  onClose: () => void;
};

const RollbackConfirmedTransaction: FC<PropsTypes> = ({ transactionToRollback, onClose, onSubmit }) => {
  const { companyId = "0" } = useParams<{ companyId: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [dependentTransactions, setDependentTransactions] = useState<Transaction[]>();

  const { rollbackTransaction } = useTransactionsService(companyId);

  const handleSubmit = async () => {
    setIsLoading(true);

    const { hasErrorsAfterEdit, failedTransactions } = await rollbackTransaction(transactionToRollback.transactionId);

    if (hasErrorsAfterEdit && failedTransactions.length) {
      setDependentTransactions(failedTransactions);
      setIsLoading(false);
    }

    if (hasErrorsAfterEdit) {
      setIsLoading(false);
      return false;
    }

    const isDeleted = await onSubmit(transactionToRollback.transactionId, true);

    if (isDeleted) {
      onClose();
      setIsLoading(false);
    } else {
      setIsLoading(false);
    }
  };

  return !dependentTransactions?.length ? (
    <Confirm
      onSubmit={handleSubmit}
      onClose={onClose}
      isLoading={isLoading}
      shareholderName={transactionToRollback?.toName || ""} // case for bundle not covered yet
      numberOfShares={transactionToRollback.numberOfShares}
    />
  ) : (
    <DependentTransactions transactions={dependentTransactions} onClose={onClose} />
  );
};

export default RollbackConfirmedTransaction;
