import { memo, useEffect, useMemo, useState } from "react";
import { AxisTick } from "@nivo/axes";
import axios from "axios";
import cn from "classnames";
import { format, parseISO } from "date-fns";
import { useFormikContext } from "formik";
import * as R from "ramda";

import { StepMonoChart, Ui } from "common/components/atoms";
import { getFiveIndexes } from "common/helpers";
import useFormatNumbers, { formatNumber } from "common/hooks/useFormatNumbers";
import { TimeHourglassIcon } from "common/icons/svg";
import { singleStepChartProps } from "common/types/Charts.types";
import { scssVariables } from "common/utils/constants";
import { createTranslation, TranslationNS } from "translation";

import { OwnershipPlanForm } from "../../../CreateGrantOneOffPlan";
import classes from "../Summary.module.scss";

const t = createTranslation(TranslationNS.pages, "company.grantOneOffRSAPlan.summary");

const TimeBaseVestingChart = () => {
  const fNumber = useFormatNumbers();
  const [chartData, setChartData] = useState<singleStepChartProps>({
    id: "ScheduleChart",
    color: scssVariables.information500,
    data: [],
  });

  const { values } = useFormikContext<OwnershipPlanForm>();

  useEffect(() => {
    axios
      .post("/api/vesting/calculate-points", {
        numberOfShares: values.numberOfShares,
        vestingPeriod: values.vestingPeriod,
        vestingInterval: values.vestingInterval,
        vestingCliff: values.vestingCliff,
        vestingStartDate: null,
      })
      .then((res) => {
        if (res.data) {
          const newData = res.data?.dataPoints?.map((item: { x: string; y: number }) => ({
            x: format(parseISO(item.x), "MM/dd/yyyy"),
            y: item.y,
            content: format(parseISO(item.x), "MM/dd/yyyy"),
          }));

          setChartData((prevState) => {
            return {
              id: prevState.id,
              color: prevState.color,
              data: newData,
            };
          });
        }
      });
  }, [values.numberOfShares, values.vestingCliff, values.vestingInterval, values.vestingPeriod]);

  const marginLeft = formatNumber(Math.max(...(R.pluck("y", chartData.data || []) as number[]))).length * 8 + 10;

  const tickBottomValues = useMemo(() => {
    const data = chartData.data || [];
    if (data.length < 6) {
      return chartData.data.map((el) => el.x);
    }
    return getFiveIndexes(data.length).map((index) => data[index].x);
  }, [chartData.data]);

  const dataNew = useMemo<singleStepChartProps[]>(() => {
    const otherData = tickBottomValues?.map((el, index) => {
      return {
        id: `other${index}`,
        color: "#A3A09D",
        strokeWidth: 1,
        strokeDasharray: "14, 8",
        data: [
          { x: el, y: 0 },
          { x: el, y: chartData.data.find((item) => item.x === el)?.y || 0 },
        ],
      };
    });

    return [{ ...chartData }, ...otherData];
  }, [chartData, tickBottomValues]);

  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <TimeHourglassIcon />
        <Ui.m className="fw-500">{t("timeBasedVesting")}</Ui.m>
      </div>

      <div className={classes["chart-container"]}>
        <div className={classes["header"]}>
          <div className={classes["option"]}>
            <Ui.xs>{t("duration")}</Ui.xs>
            <Ui.m className="mt-1" bold>
              {values.vestingPeriod} {t("months")}
            </Ui.m>
          </div>

          <div className={classes["option"]}>
            <Ui.xs>{t("vestingCliff")}</Ui.xs>
            <Ui.m className="mt-1" bold>
              {values.vestingCliff} {t("months")}
            </Ui.m>
          </div>

          <div className={classes["option"]}>
            <Ui.xs>{t("vestingInterval")}</Ui.xs>
            <Ui.m className="mt-1" bold>
              {values.vestingInterval} {t("months")}
            </Ui.m>
          </div>

          <div className={classes["option"]}>
            <Ui.xs>{t("totalShares")}</Ui.xs>
            <Ui.m className="mt-1" bold>
              {fNumber(values.numberOfShares, "amount")}
            </Ui.m>
          </div>
        </div>

        <div className={cn(classes["chart"], "pt-3 pb-1 px-2")}>
          <Ui.s bold className="mb-3">
            {t.el("exampleRelease", {
              components: [<strong key={1} />],
              values: {
                shares: fNumber(values.numberOfShares, "amount"),
              },
            })}
          </Ui.s>

          <StepMonoChart
            data={dataNew}
            height={250}
            margin={{ bottom: 60, left: marginLeft, right: marginLeft, top: 8 }}
            axisLeft={{
              tickSize: 0,
              tickValues: 6,
              format: (value) => formatNumber(value),
            }}
            isInteractive={false}
            axisBottom={{
              tickSize: 0,
              tickValues: tickBottomValues,
              tickPadding: 40,
              format: (value) => format(new Date(value), "MMM dd, yyyy"),
              renderTick: (tick) => {
                return <AxisTick {...tick} textAnchor="middle" />;
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(TimeBaseVestingChart);
