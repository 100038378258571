import { FC, useCallback } from "react";
import { useFormikContext } from "formik";

import DatePicker, { DatePickerProps } from "common/components/atoms/DatePicker/DatePicker";
import Tooltip from "common/components/atoms/Tooltip/Tooltip";
import { WarningExclamationMarkIcon } from "common/icons/svg";

import classes from "./inputs.module.scss";

type DateInputProps = {
  placeholder?: string;
  disabledWhen?: number;
  nameWhen?: string;
  isOptional?: boolean;
  isWithTimeSelect?: boolean;
  rowIndex: number;
  columnId: string;
  minDate?: string;
  maxDate?: string;
  isClearable?: boolean;
};

type FormikValues = {
  [key: string]: string | Date | undefined;
};

const DateInput: FC<DateInputProps> = ({
  placeholder = "",
  disabledWhen,
  nameWhen,
  isOptional,
  isWithTimeSelect,
  rowIndex,
  columnId,
  minDate,
  maxDate,
  isClearable,
}) => {
  const { values, setFieldValue, handleBlur, errors, touched } = useFormikContext<FormikValues[]>();

  const error = errors[rowIndex]?.[columnId];
  const value = values[rowIndex]?.[columnId];
  const touchedValue = touched?.[rowIndex]?.[columnId];

  const handleInputChange = useCallback<DatePickerProps["onChange"]>(
    (e, name) => {
      if (name) {
        setFieldValue(name, e.toString() || null);
      }
    },
    [setFieldValue]
  );

  if (nameWhen && disabledWhen === values?.[rowIndex]?.[nameWhen] && !error) {
    return <div className={classes.blankField}>-</div>;
  }

  return (
    <>
      {error && touchedValue && (
        <div className={classes.errorIcon}>
          <Tooltip className={classes.errorTooltip} popupContent={error}>
            <span>
              <WarningExclamationMarkIcon />
            </span>
          </Tooltip>
        </div>
      )}
      <DatePicker
        name={`[${rowIndex}].${columnId}`}
        placeholder={placeholder}
        className={classes.dateInput}
        isClearable={isClearable}
        isOptional={isOptional}
        isDateOnlyString
        date={value}
        onChange={handleInputChange}
        onBlur={handleBlur}
        isWithTimeSelect={isWithTimeSelect}
        minDate={minDate ? new Date(minDate) : undefined}
        maxDate={maxDate ? new Date(maxDate) : undefined}
      />
    </>
  );
};

export default DateInput;
