import { FC } from "react";
import { useFormikContext } from "formik";

import Dropdown, { SearchType } from "common/components/atoms/Dropdown/Dropdown";
import Tooltip from "common/components/atoms/Tooltip/Tooltip";
import { WarningExclamationMarkIcon } from "common/icons/svg";

import classes from "./inputs.module.scss";

type SelectInputProps = {
  isSearchable?: boolean;
  options: { id: number | string; name: string }[];
  columnId: string;
  rowIndex: number;
  searchType?: SearchType;
  placeholder?: string;
  isClearable?: boolean;
};

type FormikValues = {
  [key: string]: number | undefined;
};

const SelectInput: FC<SelectInputProps> = ({
  isSearchable,
  options,
  columnId,
  rowIndex,
  placeholder,
  isClearable,
  searchType,
}) => {
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext<FormikValues[]>();

  const error = errors[rowIndex]?.[columnId];
  const value = values[rowIndex]?.[columnId];
  const touchedValue = touched?.[rowIndex]?.[columnId];

  return (
    <>
      {error && touchedValue && (
        <div className={classes.errorIcon}>
          <Tooltip className={classes.errorTooltip} popupContent={error}>
            <span>
              <WarningExclamationMarkIcon />
            </span>
          </Tooltip>
        </div>
      )}
      <Dropdown
        name={`[${rowIndex}].${columnId}`}
        className={classes.selectInput}
        isSearchable={isSearchable}
        options={options}
        optionsIsObject
        selectedValue={value}
        onChange={handleChange}
        onBlur={handleBlur}
        placeholder={placeholder}
        isClearable={isClearable}
        searchType={searchType}
      />
    </>
  );
};

export default SelectInput;
