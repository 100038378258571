import { FC, useCallback, useMemo } from "react";
import axios from "axios";
import classNames from "classnames";
import { Form, Formik } from "formik";

import { ACCESS_LEVELS, AccessLevels } from "common/access-control/types";
import { NewAvatar } from "common/components/atoms";
import Button from "common/components/atoms/Button/Button";
import ChecksGroup from "common/components/atoms/Checks/ChecksGroup";
import SlidePanel from "common/components/atoms/SlidePanel/SlidePanel";
import classes from "common/components/atoms/SlidePanel/SlidePanel.module.scss";
import { H } from "common/components/atoms/Typography";
import { CloseIcon, DeleteIcon } from "common/icons/svg";
import { notify } from "common/utils/notify/notifyFunction";
import { createTranslation, TranslationNS } from "translation";

import { apiBase } from "../../AccessControl";
import { EditUserAccessFormValues, EditUserAccessGetDTO, EditUserAccessPostDTO } from "../../types";
import FeaturesTable from "../common/features-table/features-table";
import { InviteUserFieldsNames } from "../invite-user-sidebar/invite-user-sidebar";

type PropsTypes = {
  isOpen: boolean;
  onSubmit: () => void;
  onClose: () => void;
  isLastAdmin: boolean;
  userToEdit: EditUserAccessGetDTO;
};

const t = createTranslation(TranslationNS.pages, "companySettings.accessControlPage.inviteEditForm");

const EditUserAccessSidebar: FC<PropsTypes> = ({ isOpen, onSubmit, onClose, isLastAdmin, userToEdit }) => {
  const handleSubmit = useCallback(
    async (values: EditUserAccessFormValues) => {
      try {
        const features = Object.values(values.features)
          .flatMap((features) => Object.entries(features))
          .map(([featureId, feature]) => ({ featureId: Number(featureId), accessLevel: feature.accessLevel }));

        const editDTO: EditUserAccessPostDTO = {
          isAdmin: values.isAdmin,
          companyUserId: Number(userToEdit.companyUserId),
          features,
        };

        const response = await axios.post(apiBase, editDTO);

        if (response.status === 200) {
          onClose();
          onSubmit();
          notify(t("notificationSuccess"), true, "success", 2000);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [userToEdit.companyUserId, onSubmit, onClose]
  );

  const handleRevokeAccess = useCallback(async () => {
    if (isLastAdmin) {
      // TODO
    }
    try {
      const response = await axios.delete(`${apiBase}/user/${userToEdit.companyUserId}`);
      if (response.status === 200) {
        onClose();
        onSubmit();
        notify(t("notificationRevokeSuccess"), true, "success", 2000);
      }
    } catch (e) {
      console.log(e);
    }
  }, [isLastAdmin, userToEdit.companyUserId, onClose, onSubmit]);

  const formInitialValues: EditUserAccessFormValues = useMemo(
    () => ({
      isAdmin: userToEdit.isAdmin,
      features: userToEdit.features,
    }),
    // eslint-disable-next-line
    []
  );

  return (
    <Formik initialValues={formInitialValues} onSubmit={handleSubmit}>
      {({ values, setFieldValue, isSubmitting, submitForm }) => {
        const handleChangeAccessLevel = (featureCategory: string, featureId: number, accessLevel: AccessLevels) => {
          if (accessLevel !== ACCESS_LEVELS.FULL) {
            setFieldValue("isAdmin", false);
          }

          setFieldValue("features", {
            ...values.features,
            [featureCategory]: {
              ...values.features[featureCategory],
              [featureId]: { ...values.features[featureCategory][featureId], accessLevel },
            },
          });
        };

        return (
          <SlidePanel show={isOpen} onHide={onClose}>
            <SlidePanel.Header>
              <H.xs>{t("editTitle")}</H.xs>
              <CloseIcon className={classes["header-icon"]} onClick={onClose} />
            </SlidePanel.Header>
            <Form>
              <div className="d-flex align-items-center mt-7 mb-4">
                <NewAvatar
                  imageUrl={userToEdit.profilePictureFilePath}
                  firstName={userToEdit.firstName}
                  lastName={userToEdit.lastName}
                  size="m"
                  className="me-2"
                />
                <H.xxs>{`${userToEdit?.firstName} ${userToEdit?.lastName}`}</H.xxs>
              </div>
              <ChecksGroup
                className="mb-4"
                isTouched={values.isAdmin}
                isDisabled={isLastAdmin}
                info={t("adminCheckboxDescription")}
              >
                <ChecksGroup.Check
                  label={t("adminCheckboxLabel")}
                  checked={values.isAdmin}
                  disabled={isLastAdmin}
                  onChange={(event) => {
                    setFieldValue(InviteUserFieldsNames.IsAdmin, event.target.checked);

                    if (event.target.checked) {
                      const allFeaturesLevel2 = Object.fromEntries(
                        Object.entries(userToEdit.features).map(([key, featureCategory]) => [
                          key,
                          Object.fromEntries(
                            Object.entries(featureCategory).map(([id, feature]) => [id, { ...feature, accessLevel: 2 }])
                          ),
                        ])
                      );

                      setFieldValue("features", allFeaturesLevel2);
                    }
                  }}
                />
              </ChecksGroup>
              <FeaturesTable features={values.features} onChangeAccessLevel={handleChangeAccessLevel} />
            </Form>

            <SlidePanel.Actions>
              <div className="d-flex justify-content-between align-items-center w-100">
                <div>
                  <Button
                    className={classNames("me-3", classes["button"])}
                    onClick={submitForm}
                    isDisabled={isSubmitting}
                  >
                    {t("saveChangesBtn")}
                  </Button>
                  <Button variant="secondary" className={classes["button"]} onClick={onClose} isDisabled={isSubmitting}>
                    {t("cancelBtn")}
                  </Button>
                </div>

                <Button
                  variant="tertiary"
                  className={classNames(classes["delete-btn"], classes["button"])}
                  onClick={handleRevokeAccess}
                  isDisabled={isSubmitting || isLastAdmin}
                  iconRight={<DeleteIcon width={20} height={20} />}
                >
                  {t("revokeBtn")}
                </Button>
              </div>
            </SlidePanel.Actions>
          </SlidePanel>
        );
      }}
    </Formik>
  );
};

export default EditUserAccessSidebar;
